export function displayTime(eta) {
  return eta
    ? new Date(eta).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "UTC",
      })
    : "";
}

export function displayDate(eta) {
  return eta
    ? new Date(eta).toLocaleDateString("en-US", {
        timeZone: "UTC",
      })
    : "";
}

export function displayShortDate(date) {
  return date
    ? new Date(date).toLocaleDateString("en-US", {
        timeZone: "UTC",
        day: "2-digit",
        month: "2-digit",
      })
    : "";
}

export function displayTimeDetails(timeDetails) {
  if (
    !timeDetails ||
    timeDetails.hours == undefined ||
    !timeDetails.minutes == undefined
  ) {
    return "";
  }

  return (
    (timeDetails.hours >= 10 ? "" : "0") +
    timeDetails.hours.toString() +
    ":" +
    (timeDetails.minutes >= 10 ? "" : "0") +
    timeDetails.minutes.toString()
  );
}

export function getCurrentLocalTimeWithOffset(offset) {
  let utcDate = new Date();
  let offsetMs = offset * 60 * 1000;
  let localDate = new Date(utcDate.getTime() + offsetMs);

  return localDate;
}
