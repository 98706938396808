import { useCallback, useEffect, useRef, useState } from "react";
import { pdfjs, Document, Page } from 'react-pdf';
import { PreviewModal } from "./PreviewModal";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const options = {
    standardFontDataUrl: '/standard_fonts/',
};

const maxWidth = 800;

function PdfPreview({ blob, isOpen, close }: { blob: Blob; isOpen: boolean; close: () => void }) {
    const [numPages, setNumPages] = useState<number>();
    const [containerWidth, setContainerWidth] = useState<number>();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;
        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(onResize);
            resizeObserver.observe(containerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [onResize]);

    function onDocumentLoadSuccess({ numPages: nextNumPages }): void {
        setNumPages(nextNumPages);
    }

    return (
        <PreviewModal
            isOpen={isOpen}
            close={close}
        >
            <div ref={containerRef} className="flex flex-col items-center bg-slate-500 h-full">
                <div className="overflow-y-scroll pr-2">
                    <Document file={blob} loading={"Loading..."} options={options}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (_el, index) => (
                            <div key={`page_${index + 1}`} className="flex justify-center w-full border border-black my-2">
                                <Page
                                    pageNumber={index + 1}
                                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                />
                            </div>
                        ))}
                    </Document>
                </div>
            </div>
        </PreviewModal>
    );
}

export default PdfPreview;