import { XMarkIcon, ClipboardDocumentIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import MailForwardIcon from "../../icons/MailForward";
import Modal from "react-modal";
import { beautifyTime } from "../../utils/dateutils";

const customStyles = {
    content: {
        padding: "-20px",
        paddingBottom: "-20px",
        margin: "50px"
    },
};

interface DetentionMessage {
    tripId: string;
    customerName: string;
    orderNumber: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    stopData: any;
    appointmentTime: string;
    appointmentDate: string;
    arrivalTime: string;
    chargeStartTime: {
        utc_datetime: string;
    };
    startTime: {
        utc_datetime: string;
    };
    modifiedTime: string;
    arrivalTS: string;
}

export default function InDetentionMessage({ isOpen, modifiedTime, arrivalTS,
    setIsOpen, tripId, customerName, arrivalTime,
    orderNumber, stopData, appointmentTime, appointmentDate }: DetentionMessage) {
    return (<Modal
        style={customStyles}
        isOpen={isOpen}
        overlayClassName="overlay-detention-message opacity-1"
    >
        {/* <div className="p-20 h-full" style={{ "background": "rgba(22, 22, 22, 0.6)" }}> */}
        <div className="bg-[#EDEDF6] flex justify-center items-center m-auto min-h-[830px]">
            <div className="flex flex-col right-0">
                <div className="flex flex-row gap-2">
                    <div className="basis-[95%]"></div>
                    <div className="pb-2 flex flex-row gap-8">
                        <div>
                            <MailForwardIcon className="w-6 h-6" />
                        </div>
                        <div>
                            <ClipboardDocumentIcon className="w-6 h-6" />
                        </div>
                        <div>
                            <ArrowDownTrayIcon className="w-6 h-6" />
                        </div>
                    </div>
                </div>
                <div className="bg-[#FFFFFF] py-20 px-20 rounded shadow-lg m-auto">
                    Dear Customer,<br />
                    Trip #{tripId} is now in detention. Our truck arrived on time as per the appointment and has been at the facility for {beautifyTime((+new Date(modifiedTime) - +new Date(arrivalTS))/1000, "0 min", {
                        minuteUnit: "mins",
                        hourUnit: "hour",
                    })}. Below is the stop information:<br /><br />

                    Customer Order Number: {orderNumber}<br />
                    Customer: {customerName}<br />
                    Location: {stopData?.location?.city}, {stopData?.location?.state}<br />
                    Appointment Date: {appointmentDate}<br />
                    Appointment Time: {appointmentTime}<br />
                    Arrival time: {arrivalTime}<br /><br />

                    Please contact the facility and relieve our truck and driver.<br /><br />

                    Thank you!<br /><br />

                    Dispatch Team<br />
                    Tamana Truck Lines
                </div>
            </div>
        </div>
        {/* </div> */}
        <span
            className={`fixed top-20 right-20 p-3`}
        >
            <XMarkIcon
                className="w-6 h-6"
                onClick={() => setIsOpen(false)}
            />
        </span>
    </Modal>)
}