import useAuthGuard from "../../hooks/useAuthGuard";
export default function Loads() {
    useAuthGuard();
    //const response = await fetch(process.env.REACT_APP_API_SERVER + '/drivers/samsara')
    //console.log(response)
    //const res = await response.json()
    // console.log(res.data)

    // res.data.map((driver) => {
    //     console.log(driver.name)
    // })

    //const data = res.data
    console.log("Loads")

    return (
        <div>
            <h1 className="text-green-500">Loads</h1>
            {/* { data?.map((driver:any) => {
                return(
                <p key={driver.id}>{driver.name}</p>
                )
            })} */}
        </div>
    );
  }