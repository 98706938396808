import { combineReducers } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getToken, isTokenExpired, refreshUserToken, signOut } from "../../firebase/config";

export const resetStore = createAction("RESET_STORE");

export const handleErrorAction = async (error) => {
  if (error.action) {
    switch (error.action) {
      case 'refreshToken':
        await refreshUserToken();
        break;
      case 'forceLogout':
        await signOut();
        break;
      default:
        console.log("Error");
        console.error(error);
        break;
    }
  }
}

type RequestHeaders = {
  [key: string]: string;
};

const withAuthHeader = async (
  headers: RequestHeaders
): Promise<RequestHeaders> => {
  let token = await getToken();
  if (!token) {
    return headers;
  } else {
    const tokenExpired = await isTokenExpired();
    if (tokenExpired) {
      token = await refreshUserToken();
      return {
        ...headers,
        Authorization: "Bearer " + token,
      };
    } else {
      return {
        ...headers,
        Authorization: "Bearer " + token,
      };
    }
  }
};

// Reducers
const initialState = {
  data: [],
  notes: [],
  notesModified: false,
  loading: false,
  error: null,
  clickIndex: "",
  etaTab: 0,
};

export const addNote = async (trip_id, notes_text) => {
  const requestOptions = {
    method: "POST",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify({ trip_id: trip_id, note: notes_text }),
  };
  return fetch(process.env.REACT_APP_API_SERVER + "/notes/", requestOptions);
};

export const updateNote = async (note_id, notes_text) => {
  const requestOptions = {
    method: "PUT",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify({ note: notes_text }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/notes/" + note_id,
    requestOptions
  );
};

export const deleteNote = async (note_id) => {
  if (!note_id) {
    return;
  }
  const requestOptions = {
    method: "DELETE",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/notes/" + note_id,
    requestOptions
  );
};

// Create an async thunk action
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async (_, { rejectWithValue }) => {
    console.log("Getting data . . .");
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/aggregation/loads",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              username: process.env.REACT_APP_ALYVS_USERNAME,
              password: process.env.REACT_APP_ALYVS_PASSWORD,
              token: process.env.REACT_APP_SAMSARA_KEY,
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || "Network response was not ok");
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      handleErrorAction(error);
      return rejectWithValue(error.message);
    }
  }
);

export const notesData = createAsyncThunk(
  "notes/notes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER + "/notes/",
        {
          mode: "cors",
          method: "GET",
          headers: await withAuthHeader({
            Accept: "application/json",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Reponse Data ==> " + data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for the data with reducers to handle the actions
const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setClickIndex: (state, action) => {
      state.clickIndex = action.payload;
    },
    setEtaTab: (state, action) => {
      state.etaTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Create a slice for the data with reducers to handle the actions
const notesSlice = createSlice({
  name: "notesData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(notesData.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(notesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Combine Reducers
const appReducer = combineReducers({
  data: dataSlice.reducer,
  notes: notesSlice.reducer,
  // Add other reducers here if you have more
});

const rootReducer = (state, action) => {
  if (resetStore.match(action)) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const { setClickIndex, setEtaTab } = dataSlice.actions;

export default rootReducer;
