import React from "react"; 
export default function exceptionCard({children, className} : {
    children: React.ReactNode,
    className?: string
}) { 
    return (
        <>
            <div className={`rounded-lg bg-gray-50 px-3 py-4 ${className}`}> 
               {children}
            </div>
        </>
    );
}
