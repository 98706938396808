import { isExternalDriver } from "../../utils/triputils";
import CurrentLocationCard from "../CurrentLocation/currentlocation";
import CustomerCard from "../CustomerCard/customerCard";
import DriverCard from "../Driver/driver";
import TripDetails from "../TripDetails/tripDetails";
import DetentionSummaryCard from "../Detention/DetentionSummaryCard";
import DetentionCard from "../Detention/DetentionCard";
import ExceptionNotesSection from "../ExceptionNotesSection/exceptionnotessection";
import { useAppSelector } from "../../redux/hooks";
import ExceptionDetailsHeader from "../ExceptionDetailsHeader/ExceptionDetailsHeader";
import ExceptionDetailTitle from "../ExceptionDetailsHeader/ExceptionDetailTitle";
import { ExceptionType } from "../../types";

export function DetentionExceptionDetails({ curr_data, notes }) {
    const etaTab = useAppSelector((state) => state.data.etaTab);
    return (
        <>
            <ExceptionDetailTitle trip={curr_data} module={ExceptionType.DETENTION} />
            <ExceptionDetailsHeader curr_data={curr_data} notes={notes} />
            {curr_data?.map((delivery, index) => (
                <div
                    className="flex flex-row gap-4 mb-4 w-full"
                    key={`del_${index}`}
                >
                    <div className="w-3/4 flex flex-col gap-4">
                        <div className="flex w-full">
                            <DriverCard
                                driverName={delivery?.driver?.driver_name}
                                contactPhone={delivery?.driver?.contact_phone}
                                truckNum={delivery?.truck_num}
                                trailerNum={delivery?.trailer_num}
                            />
                        </div>
                        <div className="flex w-full">
                            <CustomerCard
                                customerName={delivery?.customer_name}
                                loadNum={delivery?.load_num}
                                tmsLinkInfo={delivery?.external_tms_summary}
                            />
                        </div>
                    </div>
                    <div className="flex p-4 w-1/4 rounded bg-gray-50 text-sm justify-center">
                        <CurrentLocationCard
                            address={delivery?.current_location?.address1}
                            isLate={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.is_late : false}
                            eta={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.eta : null}
                            lastUpdated={delivery?.current_location?.last_updated}
                            delivery_timezone={delivery?.delivery_timezone}
                            isExternalDriver={isExternalDriver(curr_data[0]?.driver)}
                        />
                    </div>
                </div>
            ))}
            <div className="w-3/4 flex flex-col gap-4 my-4 pr-3">
                <DetentionSummaryCard
                    detentionHours={curr_data[0]?.detention_summary?.detention_total_duration}
                    detentionRate={curr_data[0]?.detention_summary?.detention_rate}
                    detentionAmount={curr_data[0]?.detention_summary?.detention_total_amount}
                    lossHours={curr_data[0]?.detention_summary?.loss_total_duration}
                    lossRate={curr_data[0]?.detention_summary?.loss_rate}
                    lossAmount={curr_data[0]?.detention_summary?.loss_total_amount}
                />
            </div>
            <div className="sm:flex w-[100%]">
                <aside id="sidebar-multi-level-sidebar" className="w-72 transition-transform translate-x-0" aria-label="Sidebar">
                    <TripDetails
                        curr_data={curr_data}
                        active_stop_num={curr_data[0]?.active_stop_num}
                        deriveCardClassName={(stops, props) => stops?.stop_order == props.active_stop_num ? `min-h-[252px]` : `min-h-[260px]`}
                    />
                </aside>
                <div className="sm:pl-8 w-[100%]">
                    <div className="rounded-lg">
                        <div>
                            <div className="w-3/4 flex flex-col gap-4 pr-20">
                                {
                                    curr_data[0]?.detention?.map((detention, index) => (
                                        <DetentionCard
                                            key={`detention_${index}`}
                                            detention={detention}
                                            trip={curr_data?.length > 0 ? curr_data[0] : null}
                                        />
                                    ))
                                }
                            </div>
                            {curr_data[0]?.trip_num != "undefined" && <ExceptionNotesSection tripId={curr_data[0]?.trip_num} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}