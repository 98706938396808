import { mapAuthCodeToMessage } from "../../firebase/helper";
import { auth } from "../../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../redux/hooks";
import { resetStore } from "../../redux/reducer/index";
import { handleError } from "../../utils/errorhandling";
const Logo = require("../../../assets/images/navbar/logo.png");

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home");
    }
  });

  async function loginUser(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;
    let password = formData.get("password") as string;

    try {
      dispatch(resetStore());
      await signInWithEmailAndPassword(auth, email, password);
      if (auth.currentUser) {
        navigate("/home");
      }
    } catch (e) {
      handleError(e, toast, e?.code ? mapAuthCodeToMessage(e.code) || e.message : e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-6">
      <div className="flex justify-center w-full mb-20">
        <img src={Logo} width={200} height={200} alt="logo" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl bg-white p-10">
        <h2 className="mb-12 mt-2 text-center text-2xl leading-9 tracking-tight text-gray-900">
          Log in to your account
        </h2>
        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          <form className="space-y-6" onSubmit={loginUser}>
            <div>
              <label
                htmlFor="email"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="indent-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="indent-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold leading-6
                text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                  focus-visible:outline-indigo-600"
              >
                {!loading ? "Log In" : "Loading..."}
              </button>
            </div>
            <div className="justify-center text-center">
              <Link
                to="/forgot-password"
                className="text-md font-medium leading-6 text-indigo-600 hover:text-indigo-500 focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Forgot your password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
