import { isNullOrUndefined } from "./triputils";

export const formatCurrency = (amount?: number, fallback?: string): string => {
    if (isNullOrUndefined(amount) || amount === 0) {
        return fallback || "";
    }
    if (amount % 1 === 0) {
        // If the amount is an integer, format without decimal places
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        // Otherwise, format with decimal places
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    }
};