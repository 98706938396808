// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: relative;
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 11;
}

.overlay-detention-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(22, 22, 22, 0.95) !important;
    z-index: 11;
}

.overlay-map {
    background-color: rgba(255, 255, 255, 1);
}

.close-button {
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/exceptionDetailsCard/exceptionDetailsCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;IAC3C,WAAW;AACf;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,mDAAmD;IACnD,WAAW;AACf;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".modal {\n    position: relative;\n    display: flex; \n    align-items: center; \n    justify-content: center;\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(255, 255, 255, 0.95);\n    z-index: 11;\n}\n\n.overlay-detention-message {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(22, 22, 22, 0.95) !important;\n    z-index: 11;\n}\n\n.overlay-map {\n    background-color: rgba(255, 255, 255, 1);\n}\n\n.close-button {\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
