import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData } from "../../redux/reducer/index";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { SunIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../utils/errorhandling";

const ReeferCard = () => {
  const { data, completedData, dataTS, loading } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.length > 0 && (dataTS + (10 * 60 * 1000)) > Date.now()) return;
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      icon={SunIcon}
      title="Temperature Monitoring"
      tripData={{
        "Company Driver Trips": {
          Violations: getFilteredData(data, completedData, "internal", { temperatureViolation: true }).length,
          Compliant: getFilteredData(data, completedData, "internal", { temperatureViolation: false }).length,
          // Completed: getFilteredData(data, completedData, "internal", { completed: true }).length,
        },
        "External Driver Trips": {
          Violations: getFilteredData(data, completedData, "external", { temperatureViolation: true }).length,
          Compliant: getFilteredData(data, completedData, "external", { temperatureViolation: false }).length,
          // Completed: getFilteredData(data, completedData, "external", { completed: true }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          Violations: `/exceptionList?tripType=internal&temperatureViolation=true&module=tempViolation`,
          Compliant: `/exceptionList?tripType=internal&temperatureViolation=false&module=tempViolation`,
          // Completed: `/exceptionList?tripType=internal&completed=true&module=tempViolation`,
        },
        "External Driver Trips": {
          Violations: `/exceptionList?tripType=external&temperatureViolation=true&module=tempViolation`,
          Compliant: `/exceptionList?tripType=external&temperatureViolation=false&module=tempViolation`,
          // Completed: `/exceptionList?tripType=external&completed=true&module=tempViolation`,
        },
      }}
    />
  );
};

export default ReeferCard;
