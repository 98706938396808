export const isPreviewable = (filename: string) => {
    return isPdf(filename) || isImage(filename);
}

const isPdf = (filename: string) => {
    return filename.endsWith('.pdf');
}
/* 
    list of image file extensions supported by img tag
    https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
*/
const isImage = (filename: string) => {
    return filename.endsWith('.png') ||
        filename.endsWith('.jpg') ||
        filename.endsWith('.jpeg') ||
        filename.endsWith('.gif') ||
        filename.endsWith('.bmp') ||
        filename.endsWith('.webp') ||
        filename.endsWith('.ico') ||
        filename.endsWith('.apng') ||
        filename.endsWith('.avif');
}

export const getTypeForPreview = (filename: string) => {
    if (isPdf(filename)) {
        return "pdf";
    }
    if (isImage(filename)) {
        return "image";
    }
    return "";
}