import { mapAuthCodeToMessage } from "../../firebase/helper";
import { auth } from "../../firebase/config"
import { sendPasswordResetEmail } from "firebase/auth"
import { FormEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleError } from "../../utils/errorhandling";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("token")) {
      navigate('/home');
    }
  })

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true)
    event.preventDefault()
    let formData = new FormData(event.currentTarget)
    let email = formData.get('email') as string
      sendPasswordResetEmail(auth, email).then(() => {
        toast.success("Password reset email sent successfully")
        setLoading(false)
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }).catch((e) => {
        handleError(e, toast, e?.code ? mapAuthCodeToMessage(e.code) || e.message : e.message);
        setLoading(false)
      })
  }

    return (
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot Password</h2>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div className="mt-2">
                  <input id="email" name="email" type="email" autoComplete="email" required className="indent-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>
              <div>
                <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{!loading ? 'Submit' : 'Loading...'}</button>
              </div>
              <div className="justify-center text-center">
                <Link 
                  to="/login" 
                  className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }