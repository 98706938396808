import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData, fetchCompletedData } from "../../redux/reducer/index";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { SunIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../utils/errorhandling";

const ReeferCard = () => {
  const { data, dataTS, loading, completedData, completedDataTS } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!(data.length > 0 && (dataTS + (10 * 60 * 1000)) > Date.now())) {
      dispatch(fetchData()).then((actionResult) => {
        if (actionResult.type === "data/fetchData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
    if (!(completedData.length > 0 && (completedDataTS + (10 * 60 * 1000)) > Date.now())) {
      dispatch(fetchCompletedData()).then((actionResult) => {
        if (actionResult.type === "data/fetchCompletedData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      image={<img src={require("../../../assets/images/exceptionCards/detentionIcon.png")} alt="Detention" width={36} height={36} />}
      title="Detention Monitoring"
      tripData={{
        "Company Driver Trips": {
          "Pre-detention": getFilteredData(data, completedData, "internal", {
            detention: {
              preDetention: true,
            }
          }).length,
          "In-detention": getFilteredData(data, completedData, "internal", {
            detention: {
              inDetention: true,
            }
          }).length,
          "Completed": getFilteredData(data, completedData, "internal", {
            completed: true,
            detention: {
              exception: true
            }
          }).length,
        },
        "External Driver Trips": {
          "Pre-detention": getFilteredData(data, completedData, "external", {
            detention: {
              preDetention: true,
            }
          }).length,
          "In-detention": getFilteredData(data, completedData, "external", {
            detention: {
              inDetention: true,
            }
          }).length,
          "Completed": getFilteredData(data, completedData, "external", {
            completed: true,
            detention: {
              exception: true
            }
          }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          "Pre-detention": `/exceptionList?tripType=internal&preDetention=true&module=detention`,
          "In-detention": `/exceptionList?tripType=internal&inDetention=true&module=detention`,
          Completed: `/exceptionList?tripType=internal&completed=true&module=detention`,
        },
        "External Driver Trips": {
          "Pre-detention": `/exceptionList?tripType=external&preDetention=true&module=detention`,
          "In-detention": `/exceptionList?tripType=external&inDetention=true&module=detention`,
          Completed: `/exceptionList?tripType=external&completed=true&module=detention`,
        },
      }}
    />
  );
};

export default ReeferCard;
