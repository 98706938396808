import React, { useEffect } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import TripCard from "../../components/Latetrips/TripCard";
import useAuthGuard from "../../hooks/useAuthGuard";
import ReeferCard from "../../components/Latetrips/ReeferCard";
import { useAppSelector } from "../../redux/hooks";

//import H from '@here/maps-api-for-javascript';
//import "@here/maps-api-for-javascript"
//const H = dynamic(() => import('@here/maps-api-for-javascript'))
//import { lazy } from 'react';

export default function Home() {
  useAuthGuard();
  const { data, loading } = useAppSelector((state) => state.data);
  const [showTemperatureMonitoring, setShowTemperatureMonitoring] = React.useState(false);
  useEffect(() => {
    if (!loading && data.length > 0) {
      const filteredData = data.filter((trip) => Boolean(trip?.temperature_monitoring_details));
      if (filteredData.length > 0) {
        setShowTemperatureMonitoring(true);
      }
    }
  }, [loading, data]);
  // var platform.current = new H.service.Platform({
  //   'apikey': 'iw-BJ5JEtIfkIuvrwcCJ4as7hbuHG6gcAG5TvystaoU'
  // });

  // const defaultLayers = platform.current.createDefaultLayers({
  //   pois: true
  // });
  // console.log(defaultLayers)

  // var map = new H.Map(
  //   document.getElementById('mapContainer'),
  //   platform.createDefaultLayers().vector.normal.map,
  //   {
  //     zoom: 10,
  //     center: { lat: 52.5, lng: 13.4 }
  //   });

  return (
    <HomeLayout>
      <p className="text-2xl font-semibold text-gray-700 m-2">
        Exception Management
      </p>
      <hr className="mb-4 border-gray-300" />

      <div className="w-full flex flex-cols-3">
        <div className="w-fit basis-[33%] m-auto">
          <TripCard />
        </div>
        <div className="w-fit basis-[33%] m-auto">
          {showTemperatureMonitoring && <ReeferCard />}
        </div>
        <div className="w-fit basis-[33%] m-auto">
          
        </div>
      </div>
    </HomeLayout>
  );
}
