"use client";
import { useEffect, useState, useRef } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchAvailableTrucksData, fetchData, notesData, setClickIndex } from "../../redux/reducer/index";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getFilteredData } from "../../utils/triputils";
import useAuthGuard from "../../hooks/useAuthGuard";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import { ExceptionType, TripTiming, TripType } from "../../types";
import { handleError } from "../../utils/errorhandling";

import {AvailableTruckListDataFromAPI} from "./availableTrucksDataFromAPI"
import { displayDate, displayDateWithOffset, displayTimeWithOffset } from "../../utils/dateutils";
import {bucketDataAvailableTrucks} from "../../utils/availableTrucks";

const AvailableTrucksList: React.FC = () => {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { availableTrucks, loading } = useAppSelector((state) => state.availableTrucks);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bucket = queryParams.get("bucket");

  // Download data from an API and store it in the state
  useEffect(() => {
    dispatch(fetchAvailableTrucksData()).then((actionResult) => {
      if (actionResult.type === "availableTrucksData/availableTrucksData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
    //dispatch(notesData());
  }, [dispatch]);

  const getData = (bucket) => {
    bucketDataAvailableTrucks([...availableTrucks])
    if (bucket === "UNASSIGNED") 
      return bucketDataAvailableTrucks([...availableTrucks]).UNASSIGNED;
    else if (bucket === "PAST")
      return bucketDataAvailableTrucks([...availableTrucks]).PAST;
    else if (bucket === "TODAY")
      return bucketDataAvailableTrucks([...availableTrucks]).TODAY;
    else if (bucket === "TOMORROW")
      return bucketDataAvailableTrucks([...availableTrucks]).TOMORROW;
    else
      return [...availableTrucks];
  }

  const renderTable = () => {
    return (
      <div className="flex flex-col overflow-x-auto">
        <table
          className="whitespace-nowrap overflow-x-auto 
          min-w-full text-sm text-left rtl:text-right text-white"
        >
          <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
            <tr className="shadow-md">
              <th scope="col" className="rounded-tl-lg rounded-bl-lg px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Time
              </th>
              <th scope="col" className="px-6 py-3">
                Truck #
              </th>
              <th scope="col" className="px-6 py-3">
                Trip #
              </th>
              <th scope="col" className="px-6 py-3">
                Last Stop Name
              </th>
              <th scope="col" className="px-6 py-3">
                Customer
              </th>
              <th scope="col" className="px-6 py-3">
                Driver
              </th>
              <th scope="col" className="px-6 py-3">
                City
              </th>
              <th scope="col" className="px-6 py-3">
                State
              </th>
              <th scope="col" className="px-6 py-3">
                Driver Home
              </th>
              <th scope="col" className="px-6 py-3">
                Home Time Start
              </th>
              <th scope="col" className="px-6 py-3">
                Home Time End
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className="rounded-lg bg-tableData-bg border-b
              text-gray-900 font-semibold"
            >
              <td colSpan={8} className="px-6 py-3"></td>
            </tr>
            {getData(bucket)?.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => {console.log(item)}}
                  key={item.truck_id}
                  className="shadow-md bg-white
                  text-gray-900 font-semibold hover:cursor-pointer"
                >
                  <td className="px-6 py-3 rounded-tl-lg rounded-bl-lg">
                    {displayDateWithOffset(item?.last_stop?.datetime, item?.last_stop?.timezone_offset)}
                  </td>
                  <td className="px-6 py-3">{displayTimeWithOffset(item?.last_stop?.datetime, item?.last_stop?.timezone_offset)}</td>
                  <td className="px-6 py-3">{item?.truck_id}</td>
                  <td className="px-6 py-3">{item?.trip_number}</td>
                  <td className="px-6 py-3">{item?.last_stop?.name}</td>
                  <td className="px-6 py-3">{item?.last_stop?.customer_name}</td>
                  <td className="px-6 py-3">{item?.driver?.name}</td>
                  <td className="px-6 py-3">{item?.last_stop?.address?.city}</td>
                  <td className="px-6 py-3">{item?.last_stop?.address?.state}</td>
                  <td className="px-6 py-3">{'city' in item?.driver.address ? item?.driver?.address?.city + ', ' + item?.driver?.address?.state : null}</td>
                  <td className="px-6 py-3">{displayDate(item?.event_details?.start_date_time)}</td>
                  <td className="px-6 py-3">{displayDate(item?.event_details?.end_date_time)}</td>
                </tr>
                <tr>
                  <td colSpan={8} className="px-6 py-1"></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };




  if (loading) {
    return (
      <HomeLayout>
        <Loading />
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      <div className="sm:px-11 sm:mt-4">
        <div
          className="text-xl mb-2 text-titleColor font-semibold"
        >
          {'Available Trucks'}
        </div>
        <div className="mb-4 flex flex-row gap-10">
        </div>
        <div className="mb-4 flex flex-row gap-10">
        </div>

        <div id="default-tab-content">
          <div role="tabpanel">
            {renderTable()}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default AvailableTrucksList;
