import React, { useEffect, useRef } from "react";
import { XMarkIcon, PaperClipIcon, TrashIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { addNote } from "../../redux/reducer";
import { handleError } from "../../utils/errorhandling";

type NoteItemProps = {
    tripId: string;
    noteSaved?: () => void;
};

const CreateNote: React.FC<NoteItemProps> = ({ tripId, noteSaved }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [notesUploads, setNotesUploads] = React.useState([]);
    const [notesText, setNotesText] = React.useState("");
    const [noteCreateLoading, setNoteCreateLoading] = React.useState(false);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    const handleUpload = () => {
        if (fileInputRef.current && !noteCreateLoading) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const validFiles = Array.from(files).filter(file => {
                if (file.size > 50 * 1048576) { // 50 MB in bytes
                    handleError(null, toast, `File ${file.name} exceeds the 50 MB size limit`);
                    return false;
                }
                return true;
            });

            if (validFiles.length === 0) {
                return;
            }

            setNotesUploads((prev) => [
                ...prev,
                ...validFiles
            ]);
        }
    };

    const handleSaveNoteClick = async () => {
        setNoteCreateLoading(true);
        addNote(tripId, notesText)
            .then(async (res) => {
                if (!res.success) {
                    setNoteCreateLoading(false);
                    handleError(res, toast, res.message);
                } else {
                    const formData = new FormData();
                    if (notesUploads.length > 0) {
                        notesUploads.forEach((file) => {
                            formData.append('files', file);
                        });
                        try {
                            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${res?.data?.id}/files`, {
                                method: 'POST',
                                body: formData,
                            });

                            if (!response.ok) {
                                const data = await response.json();
                                handleError(data, toast, data?.detail || data?.error || 'Error uploading file');
                            }
                        } catch (error) {
                            console.error('Error uploading file:', error);
                        } finally {
                            setNoteCreateLoading(false);
                        }
                    }
                    noteSaved();
                }
            }).catch((error) => {
                setNoteCreateLoading(false);
                handleError(error, toast, 'Error creating note ' + error?.message);
            });
    };

    return (
        <div className="flex flex-col rounded-xl bg-gray-50 px-12 py-4 mb-4 gap-4">
            <div className="justify-end flex flex-row gap-10">
                <div>
                    <PaperClipIcon
                        className="ml-auto cursor-pointer h-5 w-5 text-black"
                        aria-hidden="true"
                        onClick={handleUpload} />
                </div>
                <div>
                    {
                        <XMarkIcon
                            className="ml-auto cursor-pointer h-5 w-5 text-black"
                            aria-hidden="true"
                            onClick={() => { noteSaved(); }}
                        />
                    }
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                    className="hidden"
                />
            </div>
            <div>
                <textarea
                    ref={textareaRef}
                    className="w-full h-full text-sm font-medium resize-none rounded-[7px] border border-black p-3"
                    value={notesText}
                    disabled={noteCreateLoading}
                    placeholder="Add Note"
                    onChange={(e) => setNotesText(e.target.value)}
                />
            </div>
            <div className="flex justify-end">
                <button className="bg-transparent text-black rounded"
                disabled={noteCreateLoading}
                onClick={() => handleSaveNoteClick()}>Save Note <i className="pl-1 fa fa-floppy-o fa-lg"></i></button>
            </div>
            <div className="flex flex-row flex-wrap justify-end gap-6">
                {
                    notesUploads.map((upload, index) => (
                        <div key={index} className="flex flex-row gap-2 text-sm text-black">
                            <p className="font-bold text-[#7a7cc0]">{upload.name}</p>
                            <TrashIcon
                                onClick={() => {
                                    setNotesUploads((prev) => prev.filter((_, i) => i !== index));
                                }}
                                className="cursor-pointer h-5 w-5"
                                aria-hidden="true"
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default CreateNote;