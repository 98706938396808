"use client";
import { useEffect, useState, useRef } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData, notesData, setClickIndex } from "../../redux/reducer/index";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getFilteredData } from "../../utils/triputils";
import useAuthGuard from "../../hooks/useAuthGuard";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/currency";
import { ExceptionType, TripTiming, TripType } from "../../types";
import { handleError } from "../../utils/errorhandling";

const ExceptionList: React.FC = () => {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, completedData, loading } = useAppSelector((state) => state.data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const module = queryParams.get("module") || ExceptionType.ETA;
  const tripTiming = queryParams.get("tripTiming");
  const temperatureViolation = module === ExceptionType.TEMP_VIOLATION ? queryParams.get("temperatureViolation") === "true" : undefined;
  const preDetention = module === ExceptionType.DETENTION ? queryParams.get("preDetention") === "true" : undefined;
  const inDetention = module === ExceptionType.DETENTION ? queryParams.get("inDetention") === "true" : undefined;
  const completed = queryParams.get("completed") === "true";
  const tripType = queryParams.get("tripType");
  const [selectedOption, setSelectedOption] = useState<TripTiming>((tripTiming || "") as TripTiming);

  const checkModule = (moduleToCheck) => {
    return module === moduleToCheck;
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    navigate(`/exceptionList?tripType=${activeTab}&tripTiming=${event.target.value}`);
  };

  // Download data from an API and store it in the state
  useEffect(() => {
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
    dispatch(notesData());
  }, [dispatch]);

  const renderTable = () => {
    return (
      <div className="flex flex-col overflow-x-auto">
        <table
          className="whitespace-nowrap overflow-x-auto 
          min-w-full text-sm text-left rtl:text-right text-white"
        >
          <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
            <tr className="shadow-md">
              <th scope="col" className="rounded-tl-lg rounded-bl-lg px-6 py-3">
                Trip #
              </th>
              <th scope="col" className="px-6 py-3">
                Truck #
              </th>
              <th scope="col" className="px-6 py-3">
                Trailer #
              </th>
              <th scope="col" className="px-6 py-3">
                Driver Name
              </th>
              <th scope="col" className="px-6 py-3">
                Client Name
              </th>
              <th scope="col" className="px-6 py-3">
                Order #:
              </th>
              {checkModule(ExceptionType.DETENTION) && <th scope="col" className="px-6 py-3">
                Detention Amount
              </th>}
              {checkModule(ExceptionType.DETENTION) && <th scope="col" className="px-6 py-3">
                Loss Amount
              </th>}
            </tr>
          </thead>
          <tbody>
            <tr
              className="rounded-lg bg-tableData-bg border-b
              text-gray-900 font-semibold"
            >
              <td colSpan={8} className="px-6 py-3"></td>
            </tr>
            {getFilteredData(data, completedData, activeTab, {
              tripTiming: selectedOption,
              temperatureViolation: temperatureViolation,
              completed,
              detention: {
                preDetention: preDetention,
                inDetention: inDetention,
              }
            })?.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => handleClick(item.trip_num)}
                  key={item.id}
                  className="shadow-md bg-white
                  text-gray-900 font-semibold hover:cursor-pointer"
                >
                  <td className="px-6 py-3 rounded-tl-lg rounded-bl-lg">
                    {item.trip_num}
                  </td>
                  <td className="px-6 py-3">{item.truck_num}</td>
                  <td className="px-6 py-3">{item.trailer_num}</td>
                  <td className="px-6 py-3">{item.driver.driver_name}</td>
                  <td className="px-6 py-3">{item.customer_name}</td>
                  <td className="px-6 py-3">{item.load_num}</td>
                  {checkModule(ExceptionType.DETENTION) && <td className="px-6 py-3">{formatCurrency(item?.detention_summary?.detention_total_amount || 0)}</td>}
                  {checkModule(ExceptionType.DETENTION) && <td className="px-6 py-3">{formatCurrency(item?.detention_summary?.loss_total_amount || 0)}</td>}
                </tr>
                <tr>
                  <td colSpan={8} className="px-6 py-1"></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const [activeTab, setActiveTab] = useState<TripType>((tripType || "internal") as TripType);
  const handleTabClick = (tabId: TripType) => {
    setActiveTab(tabId);
    navigate(`/exceptionList?tripType=${tabId}&tripTiming=${selectedOption}&module=${module}&temperatureViolation=${temperatureViolation}&preDetention=${preDetention}&inDetention=${inDetention}&completed=${completed}`);
  };

  const handleClick = (trip_num) => {
    dispatch(setClickIndex(trip_num));
    navigate(`/exceptionDetails?id=${trip_num}&module=${module}${completed ? "&completed=true" : ""}`);
  };

  const getTitle = () => {
    switch (module) {
      case ExceptionType.ETA:
        return "Trip ETA";
      case ExceptionType.TEMP_VIOLATION:
        return "Temperature Monitoring";
      case ExceptionType.DETENTION:
        return "Detentions";
      default:
        return "Trip ETA";
    }
  }

  const Map = (props) => {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);
    const { apikey } = props;
  };

  if (loading) {
    return (
      <HomeLayout>
        <Loading />
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      <div className="sm:px-11 sm:mt-4">
        <div
          className="text-xl mb-2 text-titleColor font-semibold"
        >
          {getTitle()}
        </div>

        <div className="mb-4 flex flex-row gap-10">
          <div className="flex flex-row">
            <ul
              className="w-fit border-b-2 border-[#A8B0B9] 
                  flex flex-wrap -mb-px text-sm font-medium text-center"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
            >
              <li className="me-6 pl-3 mb-[-1px]" role="presentation">
                <button
                  className={`inline-block py-2 rounded-t-lg 
                ${
                  activeTab === "internal"
                    ? "border-activeTabHeadingsBorder text-activeTabHeadingsColor border-b-[3.5px]"
                    : "text-tabHeadingsColor"
                }`}
                  onClick={() => handleTabClick("internal")}
                >
                  Company Driver
                </button>
              </li>

              <li className="me-6 mb-[-1px]" role="presentation">
                <button
                  className={`inline-block py-2 rounded-t-lg 
                      ${
                        activeTab === "external"
                          ? "border-activeTabHeadingsBorder text-activeTabHeadingsColor border-b-[3.5px]"
                          : "text-tabHeadingsColor"
                      }`}
                  onClick={() => handleTabClick("external")}
                >
                  External Driver
                </button>
              </li>
            </ul>
          </div>
          {tripTiming && <div className="flex items-center space-x-10">
            <label>
              <input
                type="radio"
                value="onTimeTrips"
                checked={selectedOption === "onTimeTrips"}
                onChange={handleChange}
                className="form-radio accent-activeTabHeadingsBorder"
              />
              <span className="ml-2">On Time Trips</span>
            </label>
            <label>
              <input
                type="radio"
                value="lateTrips"
                checked={selectedOption === "lateTrips"}
                onChange={handleChange}
                className="form-radio accent-activeTabHeadingsBorder"
              />
              <span className="ml-2">Late Trips</span>
            </label>
          </div>}
          {/* {selectedOption === "lateTrips" && (
            <div className="flex flex-row">
              <Dropdown
                options={["Open", "In Progress", "Resolved"]}
                label={"Status"}
              />
            </div>
          )} */}
        </div>

        <div id="default-tab-content">
          <div
            className={`${activeTab === "internal" ? "block" : "hidden"}`}
            role="tabpanel"
          >
            {renderTable()}
          </div>
          <div
            className={`${activeTab !== "internal" ? "block" : "hidden"}`}
            role="tabpanel"
          >
            {renderTable()}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default ExceptionList;
