import useAuthGuard from "../../hooks/useAuthGuard";
export default function Loads() {
    useAuthGuard();
    //const response = await fetch(process.env.REACT_APP_API_SERVER + '/drivers/samsara')
    //const res = await response.json()

    // res.data.map((driver) => {
    // })

    //const data = res.data

    return (
        <div>
            <h1 className="text-green-500">Loads</h1>
            {/* { data?.map((driver:any) => {
                return(
                <p key={driver.id}>{driver.name}</p>
                )
            })} */}
        </div>
    );
  }