import React, { useRef } from "react";
import { PencilIcon, XMarkIcon, PaperClipIcon, ArrowUpTrayIcon, ArrowDownTrayIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/solid";
import { ConfirmationPopup } from "../ConfirmationPopup";
import { toast } from "react-toastify";
import { deleteNote } from "../../redux/reducer";
import { isPreviewable } from "../../utils/fileutils";
import { FilePreview } from "../FilePreview/FilePreview";
import { handleError } from "../../utils/errorhandling";

type NoteItemProps = {
  noteId: string;
  createTime: string;
  note: string | Record<string, any>;
  type: "User" | "System";
  createdBy?: string;
  uploads: {
    file_name: string;
    s3_key: string;
    created_at: string;
    uploading?: boolean;
  }[];
  reloadNotes: () => void
};

const NoteItem: React.FC<NoteItemProps> = ({ noteId, createTime, note, type, createdBy, uploads, reloadNotes }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef(null);
  const [notesUploads, setNotesUploads] = React.useState(uploads || []);
  const [editMode, setEditMode] = React.useState(false);
  const [prevNote, setPrevNote] = React.useState(note);
  const [notesText, setNotesText] = React.useState(note || "");
  const [s3Key, setS3Key] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showDeletionConfirmation, setShowDeletionConfirmation] = React.useState(false);
  const [blob, setBlob] = React.useState(null);
  const [showPreview, setShowPreview] = React.useState(false);
  const [filename, setFilename] = React.useState("");

  const isValidDate = (d) => {
    if (!d) {
      return false;
    }
    if (new Date(d).toString() == "Invalid Date") {
      return false;
    }
    return true;
  }

  const refreshNote = (noteObj: any) => {
    setNotesText(noteObj.note);
    setNotesUploads(noteObj.uploads || []);
    setPrevNote(noteObj.note);
  }

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        if (file.size > 50 * 1048576) { // 50 MB in bytes
          handleError(null, toast, `File ${file.name} exceeds the 50 MB size limit`);
          return false;
        }
        return true;
      });

      if (validFiles.length === 0) {
        return;
      }

      setNotesUploads((prev) => [
        ...prev,
        ...validFiles.map(file => {
          return {
            file_name: file.name,
            s3_key: file.name,
            created_at: new Date().toISOString(),
            uploading: true,
          }
        })]);
      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files`, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        if (!response.ok) {
          handleError(data, toast, data?.detail || data?.error || 'Error uploading file');
          reloadNotes();
        } else {
          refreshNote(data);
        }

      } catch (error) {
        handleError(error, toast, 'Error uploading file ' + error.message);
        reloadNotes();
      }
    }
  };

  const handleFileDelete = async (s3_key: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files?s3_key=${encodeURIComponent(s3_key)}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        handleError(response, toast, 'File delete failed');
        return;
      }

      const result = await response.json();
      refreshNote(result);
    } catch (error) {
      handleError(error, toast, 'Error deleting file');
    }
  };

  const handleDelete = async () => {
    deleteNote(noteId)
      .then(() => {
        reloadNotes();
      })
  }

  const getBlob = async (s3_key: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files?s3_key=${encodeURIComponent(s3_key)}`, {
      method: 'GET',
    });

    if (!response.ok) {
      handleError(response, toast, 'File download failed');
      return;
    }

    const blob = await response.blob();
    return blob;
  }

  const handleDownload = async (s3_key: string, filename: string) => {
    try {
      const blob = await getBlob(s3_key);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = filename || 'download';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error, toast, 'Error downloading file');
    }
  };

  const updateNoteText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note: notesText }),
      });

      const result = await response.json();
      if (!response.ok) {
        handleError(result, toast, result?.detail || result?.error || 'Error updating note');
      } else {
        refreshNote(result);
        setEditMode(false);
      }
    } catch (error) {
      handleError(error, toast, 'Error updating note ' + error.message);
    }
  }

  return (
    <>
      <div className="flex flex-col rounded-xl bg-gray-50 min-h-32 mb-4">
        <div className="flex flex-row gap-10 text-md text-gray-400 px-12 pt-5 bottom-1 pb-2">
          {type === "User" ? <div className="basis-[10%]">{!editMode && createdBy ? createdBy : null}</div> : <div className="basis-[10%]">System Note</div>}
          <div className="basis-[10%]">{!editMode && isValidDate(createTime) && new Date(createTime).toLocaleDateString("en-US")}</div>
          <div className="basis-[70%]">{!editMode && isValidDate(createTime) && new Date(createTime).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}</div>
          <div className="basis-[10%] flex flex-row gap-10 justify-end">
            {type !== "System" && <div>
              <TrashIcon
                className="ml-auto cursor-pointer h-5 w-5 text-black"
                aria-hidden="true"
                onClick={() => setShowDeletionConfirmation(true)} />
            </div>}
            {type !== "System" && <div>
              <PaperClipIcon
                className="ml-auto cursor-pointer h-5 w-5 text-black"
                aria-hidden="true"
                onClick={handleUpload} />
            </div>}
            {type !== "System" && <div>
              {
                editMode ? <XMarkIcon
                  className="ml-auto cursor-pointer h-5 w-5 text-black"
                  aria-hidden="true"
                  onClick={() => { setEditMode(false); setNotesText(prevNote); }}
                /> :
                  <PencilIcon
                    className="ml-auto cursor-pointer h-5 w-5 text-black"
                    aria-hidden="true"
                    onClick={() => {
                      setEditMode(true);
                      setTimeout(() => {
                        textareaRef?.current?.focus();
                        textareaRef?.current?.setSelectionRange(0, textareaRef.current.value.length);
                      }, 100);
                    }}
                  />
              }
            </div>}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
              className="hidden"
            />
          </div>
        </div>
        {!editMode && <div className="px-12">
          <hr />
        </div>}
        <div className="flex px-12 pt-2 text-md text-gray-600 overflow-x-auto">
          {type === "User" && (
            editMode ? <>
              <textarea
                ref={textareaRef}
                className="w-full h-full text-sm font-medium resize-none rounded-[7px] border border-black p-3"
                value={notesText as string}
                placeholder="Add Note"
                onChange={(e) => setNotesText(e.target.value)}
              ></textarea>
            </> : <div>{notesText as string}</div>
          )}
          {type === "System" && !editMode && (
            <div className="flex flex-row gap-8">
              {typeof note === "object" && Object.entries(note).map(([key, value]) => (
                <div key={key} className="flex gap-2">
                  <div>{key}:</div>
                  <div className="font-semibold">{value}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {editMode && <div className="flex justify-end px-12 py-2">
          <button className="bg-transparent text-black p-1 rounded" onClick={() => updateNoteText()}>Save Edits <i className="pl-1 fa fa-floppy-o fa-lg"></i></button>
        </div>}
        {!editMode && type !== "System" && <div className="flex flex-row flex-wrap justify-end pt-5 pb-2">
          {
            notesUploads.map((upload, index) => (
              <div key={index} className="flex flex-row px-6 pt-2 text-sm text-black">
                <p
                  className={`mr-2 font-bold text-[#7a7cc0] ${isPreviewable(upload.file_name) && !upload.uploading ? "cursor-pointer" : ""}`}
                  onClick={async () => {
                    if (!isPreviewable(upload.file_name) && upload.uploading) {
                      return;
                    }
                    if (filename === upload.file_name) {
                      setShowPreview(true);
                    } else {
                      setBlob(null);
                      setShowPreview(true);
                      setFilename(upload.file_name);
                      const blob = await getBlob(upload.s3_key);
                      setBlob(blob);
                    }
                  }}
                >
                  {upload.file_name}
                </p>
                <TrashIcon
                  onClick={() => { setS3Key(upload.s3_key); setShowConfirmation(true) }}
                  className="cursor-pointer h-5 w-5 mr-2"
                  aria-hidden="true"
                />
                {upload.uploading ? <ArrowUpTrayIcon
                  className="cursor-pointer h-5 w-5 mr-2"
                  aria-hidden="true"
                /> : <ArrowDownTrayIcon
                  className="cursor-pointer h-5 w-5 mr-2"
                  aria-hidden="true"
                  onClick={() => handleDownload(upload.s3_key, upload.file_name)}
                />}
              </div>
            ))
          }
        </div>
        }
      </div>
      <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={() => { setShowConfirmation(false) }}
        onConfirm={() => {
          handleFileDelete(s3Key);
          setShowConfirmation(false);
        }}
        title="Delete File"
        message="Are you sure you want to delete this file?"
      />
      <ConfirmationPopup
        isOpen={showDeletionConfirmation}
        onClose={() => { setShowDeletionConfirmation(false) }}
        onConfirm={() => {
          handleDelete();
          setShowDeletionConfirmation(false);
        }}
        title="Delete File"
        message="Are you sure you want to delete this note?"
      />
      {showPreview &&
        <FilePreview
          blob={blob}
          isOpen={showPreview}
          filename={filename}
          close={() => {
            setShowPreview(false);
          }} />}
    </>
  );
};

export default NoteItem;