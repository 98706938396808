import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
const defaultLayer = require('../../../assets/images/maps/default.png');
const satelliteLayer = require('../../../assets/images/maps/satellite.png');

function calculateRoute(platform, map, currentLocation, destinationLocation) {
  function getMarkerIcon(color) {
    const svgCircle = `<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="marker">
                    <circle cx="10" cy="10" r="7" fill="${color}" stroke="${color}" stroke-width="4" />
                    </g></svg>`;
    return new H.map.Icon(svgCircle, {
      anchor: {
        x: 10,
        y: 10
      }
    })
  }
  function routeResponseHandler(response) {
    const sections = response.routes[0].sections
    const lineStrings = []

    sections.forEach((section) => {
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
    })

    const multiLineString = new H.geo.MultiLineString(lineStrings);
    let lat_min = currentLocation.lat < destinationLocation.lat ? currentLocation.lat : destinationLocation.lat
    let lat_max = currentLocation.lat > destinationLocation.lat ? currentLocation.lat : destinationLocation.lat
    let lng_min = currentLocation.lng < destinationLocation.lng ? currentLocation.lng : destinationLocation.lng
    let lng_max = currentLocation.lng > destinationLocation.lng ? currentLocation.lng : destinationLocation.lng
    let padding_lat = (lat_max - lat_min) * .2
    let padding_lng = (lng_max - lng_min) * .2

    var boundingBox = new H.geo.Rect(lat_min - padding_lat,
      lng_min - padding_lng,
      lat_max + padding_lat,
      lng_max + padding_lng);

    // Create the polyline for the route
    const routePolyline = new H.map.Polyline(multiLineString, {
      style: {
        lineWidth: 5
      }
    })
    map.removeObjects(map.getObjects());
    map.addObject(routePolyline);
    map.addObjects([
      new H.map.Marker(currentLocation, {
        icon: getMarkerIcon('red')
      }),
      new H.map.Marker(destinationLocation, {
        icon: getMarkerIcon('green')
      })
    ])

    map.getViewModel().setLookAtData({
      bounds: boundingBox
    })

    // map.getViewModel().setLookAtData({
    //     bounds: bounds,
    //     zoom: map.getViewModel().getLookAtData().zoom - 1,
    //     center: currentLocation
    // });
  }
  const router = platform.getRoutingService(null, 8);
  const routingParams = {
    'origin': `${currentLocation.lat},${currentLocation.lng}`,
    'destination': `${destinationLocation.lat},${destinationLocation.lng}`,
    'transportMode': 'truck',
    'return': 'polyline'
  }
  router.calculateRoute(routingParams, routeResponseHandler, console.error)
}

export default function CreateMap(props) {
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);

  useEffect(() => {
    if (!map.current) {
      platform.current = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_KEY
      });

      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      const newMap = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
        zoom: 10,
        //center: props.currentLocation,
      }
      );

      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );

      map.current = newMap;
      setBaseLayers(defaultLayers);
      calculateRoute(platform.current, map.current, props.currentLocation, props.destinationLocation);
    } else {
      // Update the map layer when mapLayer state changes
      const defaultLayers = platform.current.createDefaultLayers();
      setBaseLayers(defaultLayers);
    }

    function setBaseLayers(defaultLayers) {
      if (props.mapLayer === 'normal') {
        map.current.setBaseLayer(defaultLayers.vector.normal.map);
      } else if (props.mapLayer === 'satellite') {
        map.current.setBaseLayer(defaultLayers.raster.satellite.map);
      }
    }
  }, [props.mapLayer]);

  const handleLayerChange = () => {
    if (props.mapLayer === 'normal') {
      props?.handleMapLayerChange();
    } else {
      props?.handleMapLayerChange();
    }
  };

  return (
    <>
      <div
        style={{ height: !props.expanded && "250px" }}
        className="w-full"
        ref={mapRef}
      >
        <div id="satellite" className={`absolute bottom-0 left-0 border p-1 text-black bg-white z-10 m-2 rounded`} onClick={handleLayerChange}>
          <img src={props.mapLayer === "satellite" ? defaultLayer : satelliteLayer} alt="logo" className="w-12 h-12" />
        </div>
      </div>
    </>
  );
}