import React, { useEffect, useRef } from "react";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { addNote } from "../../redux/reducer";

type NoteItemProps = {
    tripId: string;
    noteSaved?: () => void;
};

const CreateNote: React.FC<NoteItemProps> = ({ tripId, noteSaved }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [notesUploads, setNotesUploads] = React.useState([]);
    const [notesText, setNotesText] = React.useState("");
    const [noteCreateLoading, setNoteCreateLoading] = React.useState(false);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    const handleUpload = () => {
        if (fileInputRef.current && !noteCreateLoading) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const validFiles = Array.from(files).filter(file => {
                if (file.size > 50 * 1048576) { // 50 MB in bytes
                    toast.error(`File ${file.name} exceeds the 50 MB size limit`);
                    return false;
                }
                return true;
            });

            if (validFiles.length === 0) {
                return;
            }

            setNotesUploads((prev) => [
                ...prev,
                ...validFiles
            ]);
        }
    };

    const handleSaveNoteClick = () => {
        setNoteCreateLoading(true);
        addNote(tripId, notesText)
            .then(async (data) => {
                const formData = new FormData();
                const json = await data.json();
                if (notesUploads.length > 0) {
                    notesUploads.forEach((file) => {
                        formData.append('files', file);
                    });
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${json?.id}/files`, {
                            method: 'POST',
                            body: formData,
                        });

                        if (!response.ok) {
                            toast.error('File upload failed');
                        }
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                }
                setNoteCreateLoading(false);
                noteSaved();
            });
    };

    return (
        <>
            <div className="flex flex-col rounded-xl bg-gray-50 pl-4 py-4 pr-4 mb-4 min-h-32">
                <div className="flex flex-row gap-8">

                    <div className="basis-[95%] pl-3 text-md overflow-x-auto min-h-32">
                        <textarea
                            ref={textareaRef}
                            className="w-full h-3/4 text-sm font-medium resize-none rounded-[7px] border-2 border-bg-gray-800"
                            value={notesText}
                            placeholder="Add Note"
                            disabled={noteCreateLoading}
                            onChange={(e) => setNotesText(e.target.value)}
                        ></textarea>
                        <div className="flex justify-end">
                            <button
                                disabled={noteCreateLoading}
                                className="bg-indigo-500/100 text-white p-1 rounded"
                                onClick={() => handleSaveNoteClick()}
                            >
                                {noteCreateLoading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="flex justify-between items-center mb-2">
                        <div className="text-md font-semibold">Documents ({notesUploads.length})</div>
                        <button
                            onClick={handleUpload}
                            className="bg-indigo-500/100 text-white px-1 py-1 rounded-md"
                        >
                            <PlusIcon className="h-5 w-5" />
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            multiple
                            className="hidden"
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        {notesUploads.map((upload, index) => (
                            <div key={index} className="flex flex-row gap-1">
                                {/* <div key={upload.s3_key} className="flex justify-between items-center"> */}
                                <div className="basis-[90%]">{upload.name}</div>
                                <div className="basis-[5%]">
                                    <XMarkIcon
                                        onClick={() => {
                                            if (!noteCreateLoading) {
                                                setNotesUploads((prevUploads) => prevUploads.filter((_, i) => i !== index))
                                            }
                                        }}
                                        className="cursor-pointer h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateNote;