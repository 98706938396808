import PdfPreview from "./Pdf";
import { getTypeForPreview } from "../../utils/fileutils";
import ImagePreview from "./Image";
import { Loading } from "../Loading";
import { PreviewModal } from "./PreviewModal";

export function FilePreview({
    blob, filename, isOpen, close
}: {
    blob: Blob;
    filename: string;
    isOpen: boolean;
    close: () => void;
}) {
    const type = getTypeForPreview(filename);

    if (!blob) {
        return (
            <PreviewModal
                isOpen={isOpen}
                close={close}
            >
                <Loading />
            </PreviewModal>
        )
    }

    switch (type) {
        case "pdf":
            return <PdfPreview blob={blob} isOpen={isOpen} close={close} />;
        case "image":
            return <ImagePreview blob={blob} isOpen={isOpen} filename={filename} close={close} />;
        default:
            return <PreviewModal isOpen={isOpen} close={close}>Preview not available</PreviewModal>;
    }
}