import React, { useRef } from "react";
import { PencilIcon, XMarkIcon, CloudArrowDownIcon, CloudArrowUpIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ConfirmationPopup } from "../ConfirmationPopup";
import { toast } from "react-toastify";
import { deleteNote } from "../../redux/reducer";

type NoteItemProps = {
  noteId: string;
  createTime: string;
  note: string;
  type: "User" | "System";
  createdBy?: string;
  uploads: {
    file_name: string;
    s3_key: string;
    created_at: string;
    uploading?: boolean;
  }[];
  reloadNotes: () => void
};

const NoteItem: React.FC<NoteItemProps> = ({ noteId, createTime, note, type, createdBy, uploads, reloadNotes }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [notesUploads, setNotesUploads] = React.useState(uploads || []);
  const [editMode, setEditMode] = React.useState(false);
  const [prevNote, setPrevNote] = React.useState(note);
  const [notesText, setNotesText] = React.useState(note || "");
  const [s3Key, setS3Key] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showDeletionConfirmation, setShowDeletionConfirmation] = React.useState(false);

  const isValidDate = (d) => {
    if (!d) {
      return false;
    }
    if (new Date(d).toString() == "Invalid Date") {
      return false;
    }
    return true;
  }

  const refreshNote = (noteObj: any) => {
    setNotesText(noteObj.note);
    setNotesUploads(noteObj.uploads || []);
    setPrevNote(noteObj.note);
  }

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        if (file.size > 50 * 1048576) { // 50 MB in bytes
          toast.error(`File ${file.name} exceeds the 50 MB size limit`);
          return false;
        }
        return true;
      });

      if (validFiles.length === 0) {
        return;
      }

      setNotesUploads((prev) => [
        ...prev,
        ...validFiles.map(file => {
          return {
            file_name: file.name,
            s3_key: file.name,
            created_at: new Date().toISOString(),
            uploading: true,
          }
        })]);
      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          toast.error('File upload failed');
        }

        const result = await response.json();
        refreshNote(result);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleFileDelete = async (s3_key: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files?s3_key=${encodeURIComponent(s3_key)}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        toast.error('File delete failed');
        return;
      }

      const result = await response.json();
      refreshNote(result);
      // toast.success('File deletion successful');
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Error deleting file');
    }
  };

  const handleDelete = async () => {
    deleteNote(noteId)
      .then(() => {
        reloadNotes();
      })
      .then((data) => {
        console.log(data);
      });
  }

  const handleDownload = async (s3_key: string, filename: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}/files?s3_key=${encodeURIComponent(s3_key)}`, {
        method: 'GET',
      });

      if (!response.ok) {
        toast.error('File download failed');
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = filename || 'download';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      // toast.success('File download successful');
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Error downloading file');
    }
  };

  const updateNoteText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/notes/${noteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note: notesText }),
      });

      if (!response.ok) {
        toast.error('Note update failed');
        return;
      }

      const result = await response.json();
      refreshNote(result);
      // toast.success('Note update successful');
      setEditMode(false);
    } catch (error) {
      console.error('Error updating note:', error);
      toast.error('Error updating note:', error);
    }
  }

  return (
    <>
      <div className="flex flex-col rounded-xl bg-gray-50 pl-4 py-4 pr-4 mb-4 min-h-32">
        <div className="flex flex-row gap-8">
          <div className="flex flex-col gap-3 text-md text-gray-400 basis-[25%]">
            <div>{type || "User"} Note</div>
            {createdBy && <div>{createdBy}</div>}
            <div>{isValidDate(createTime) && new Date(createTime).toLocaleDateString("en-US")}</div>
            <div>
              {isValidDate(createTime) && new Date(createTime).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </div>
          </div>

          <div className="basis-[70%] pl-3 text-md overflow-x-auto">
            {type === "User" && (
              editMode ? <>
                <textarea
                  className="w-full h-3/4 text-sm font-medium resize-none rounded-[7px] border-2 border-bg-gray-800"
                  value={notesText}
                  placeholder="Add Note"
                  onChange={(e) => setNotesText(e.target.value)}
                ></textarea>
                <div className="flex justify-end">
                  <button className="bg-indigo-500/100 text-white p-1 rounded" onClick={() => updateNoteText()}>Save</button>
                </div>
              </> : <div>{notesText}</div>
            )}
            {type === "System" && (
              <div className="flex flex-col justify-evenly gap-3">
                {Object.entries(note).map(([key, value]) => (
                  <div key={key} className="flex gap-2">
                    <div>{key}:</div>
                    <div className="font-semibold">{value}</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {type === "User" &&
            <div className="flex flex-col gap-4 basis-[5%]">
              {
                editMode ? <XMarkIcon
                  className="ml-auto cursor-pointer h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  onClick={() => { setEditMode(false); setNotesText(prevNote); }}
                /> :
                  <PencilIcon
                    className="ml-auto cursor-pointer h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    onClick={() => setEditMode(true)}
                  />
              }
              <div>
                <TrashIcon
                  className="ml-auto cursor-pointer h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  onClick={() => setShowDeletionConfirmation(true)} />
              </div>
            </div>}
        </div>

        <div className="mt-4">
          <div className="flex justify-between items-center mb-2">
            <div className="text-md font-semibold">Documents ({notesUploads.length})</div>
            <button
              onClick={handleUpload}
              className="bg-indigo-500/100 text-white px-1 py-1 rounded-md"
            >
              <PlusIcon className="h-5 w-5" />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
              className="hidden"
            />
          </div>

          <div className="flex flex-col gap-2">
            {notesUploads.map((upload, index) => (
              <div key={index} className="flex flex-row gap-1">
                {/* <div key={upload.s3_key} className="flex justify-between items-center"> */}
                <div className="basis-[90%]">{upload.file_name}</div>
                <div className="basis-[5%]">
                  <XMarkIcon
                    onClick={() => { setS3Key(upload.s3_key); setShowConfirmation(true) }}
                    className="cursor-pointer h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="basis-[5%]">
                  {upload.uploading ? <CloudArrowUpIcon
                    className="cursor-pointer h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  /> : <CloudArrowDownIcon
                    className="cursor-pointer h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    onClick={() => handleDownload(upload.s3_key, upload.file_name)}
                  />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={() => { setShowConfirmation(false) }}
        onConfirm={() => { 
          handleFileDelete(s3Key);
          setShowConfirmation(false);
        }}
        title="Delete File"
        message="Are you sure you want to delete this file?"
      />
      <ConfirmationPopup
        isOpen={showDeletionConfirmation}
        onClose={() => { setShowDeletionConfirmation(false) }}
        onConfirm={() => { 
          handleDelete();
          setShowDeletionConfirmation(false);
        }}
        title="Delete File"
        message="Are you sure you want to delete this note?"
      />
    </>
  );
};

export default NoteItem;