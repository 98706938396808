import { isTripException } from "../../utils/triputils";

export default function ExceptionDetailTitle(props: {
    trip: any;
    module: string;
}) {
    const { trip, module } = props;
    if (!trip || !trip.length) {
        return
    }
    return (
        <>
            <p className="text-xl font-semibold text-tabHeadingsColor">
                {isTripException(trip[0], module) ? "Exception Details" : "Trip Details"}
            </p>
            <div className="border-b-2 border-[#A8B0B9] mt-2 mb-3"></div>
        </>
    );
}