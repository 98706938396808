import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

let interval;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

onAuthStateChanged(auth, async (user) => {
  if (user) {
    await refreshUserToken(false);
    scheduleRefreshToken();
  } else {
    localStorage.setItem("token", "");
  }
});

function scheduleRefreshToken() {
  if (interval) {
    clearInterval(interval);
  }
  interval = setInterval(async () => {
    await refreshUserToken();
  }, 60 * 60 * 1000);
}

export async function refreshUserToken(forceRefresh = true) {
  const tokenResult = await auth.currentUser?.getIdTokenResult(forceRefresh);
  if (tokenResult?.token) {
    localStorage.setItem("token", tokenResult.token);
    localStorage.setItem("token_exp", `${+new Date(tokenResult.expirationTime)}`);
  }
  return tokenResult?.token;
}

export async function isTokenExpired() {
  const tokenExp = localStorage.getItem("token_exp");
  if (!tokenExp) {
    return true;
  }
  return +new Date() > +tokenExp;
}

export async function signOut() {
  localStorage.setItem("token", "");
  localStorage.setItem("token_exp", "");
  return auth.signOut();
}

export async function getToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return refreshUserToken(false);
  }
  return token;
}

export { auth };
