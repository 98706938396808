import React from "react";
import Card from "../../components/card/card";

export default function TripDetails(props) { 
    return (
        <div className="text-sm">
        {props.curr_data?.map((delivery, index) => (
            <React.Fragment key={`delivery_${index}`}>
            {delivery?.stops?.map((stops, stopIndex) => (
                <div key={`stop_${stopIndex}`}>
                <div
                    className={` ${
                    stopIndex == 0
                        ? ""
                        : "border-[#A8B0B9] border-l-4 h-4 relative left-1/2"
                    }`}
                ></div>

                <div
                    className={(stops?.stop_order == props.active_stop_num ? 'border-4 border-indigo-500/100' : '')
                        + (stops?.stop_order < props.active_stop_num && stops?.stop_order != 1 ? 'opacity-40' : '')
                    }
                    style={{
                        borderRadius: "10px",
                    }}
                >
                    <Card key={stops.id}>
                    <div className="grid grid-cols-2">
                        <div
                            className={`${
                            stops?.deliver_status !== "Pending"
                                ? "bg-[#1C244A] rounded-sm w-max px-1 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase"
                                : "bg-[#d84a4c] rounded-sm w-max px-1 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase"
                            }`}
                            //update pickup and drop number dynamically.
                        >
                            {`${stops?.stop_type} ${
                            stops?.stop_type == "Pickup" ? 1 : stopIndex
                            }`}
                        </div>
                        { stops?.stop_order == props.active_stop_num &&
                            <div><i className="mt-0.5 fa-solid fa-truck"></i></div>
                        }
                    </div>
                    <div
                        // className={`mt-2 text-sm font-medium ${  stops.deliver_status !== "Pending"}? text-[#141414] : text-[red]`}

                        className={`mt-2 text-sm ${
                        stops?.deliver_status !== "Pending"
                            ? "text-[#141414]"
                            : "text-[red]"
                        }`}
                    >
                        {/* <p className="mb-2">{delivery?.stops?.length}</p> */}
                        <p className="mb-2">
                        <span className="text-[#606479]">Customer:{" "}</span>
                        {stops?.customer_name ? stops?.customer_name : "-"}{" "}
                        </p>
                        <p className="mb-2"><span className="text-[#606479]">Location: </span>{`${stops?.location.city}, ${stops?.location.state}`}</p>
                        <p className="mb-2"><span className="text-[#606479]">Date: </span>{`${new Date(
                        stops?.deliver_date
                        ).toLocaleDateString("en-US", {
                        timeZone: "UTC",
                        })}`}</p>
                        <p className="mb-2">
                        <span className="text-[#606479]">
                            Appt Type:
                        </span>{" "}
                        {`${stops?.appointment_type}`}
                        </p>
                        <p className="mb-2">
                        <span
                            className={`text-[#606479] ${
                            stops.deliver_status !== "Pending"
                                ? ""
                                : "text-[red]"
                            }`}
                        >
                            Appt Time:
                        </span>{" "}
                        {new Date(stops?.deliver_date).toLocaleTimeString(
                            "en-US",
                            {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                            timeZone: "UTC",
                            }
                        )}
                        </p>
                        <p className="mb-2"><span className="text-[#606479]">Status: </span>{`${stops?.deliver_status}`}</p>
                    </div>
                    </Card>
                </div>
                </div>
            ))}
            </React.Fragment>
        ))}
        </div>
    )
}