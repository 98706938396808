"use client";
import React from "react";
import ExceptionDetailsCard from "../exceptionDetailsCard/page";
import useAuthGuard from "../../hooks/useAuthGuard";
import HomeLayout from "../../components/Layout/homeLayout";

export default function exceptionDetails() {
  useAuthGuard();
  return (
    <HomeLayout>
      <div>
        <p className="text-xl font-semibold text-tabHeadingsColor">
          Exception Details
        </p>
        <div className="border-b-2 border-[#A8B0B9] mt-2 mb-3"></div>
        <ExceptionDetailsCard />
      </div>
    </HomeLayout>
  );
}
