import React from "react"; 
export default function exceptionCard({children}) { 
    return (
        <>
            <div className="rounded-lg bg-gray-50 px-3 py-4"> 
               {children}
            </div>
        </>
    );
}
