import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { auth } from "../../firebase/config";
const Logo = require("../../../assets/images/navbar/logo.png");
const Userprofile = require("../../../assets/images/navbar/userProfile.png");

const Navbar = () => {
  const [userEmail, setUserEmail] = useState(auth?.currentUser?.email || "");

  auth.onAuthStateChanged((user) => {
    if (user && userEmail !== user.email) {
      setUserEmail(user.email);
    }
  });

  return (
    <>
      <div className="w-full h-20 top-0 bg-layout-bg z-40">
        <div className="flex justify-between items-center h-full">
          <Link to="/home">
            <img src={Logo} width={100} height={100} alt="logo" />
          </Link>
          <ul className="hidden md:flex gap-x-10 text-[#4F5B67]">
            <li>
              <Link to="/home">
                <p>Reports</p>
              </Link>
            </li>
            <li>
              <Link to="/home">
                <p>Home</p>
              </Link>
            </li>
            <li>
              <Link to="/home">
                <p>Exceptions</p>
              </Link>
            </li>
            <li>
              <div className="flex">
                <img
                  src={
                    userEmail
                      ? `https://api.dicebear.com/7.x/initials/svg?seed=${userEmail}`
                      : Userprofile
                  }
                  width={30}
                  alt="Userprofile"
                  className="relative top-[-3px] mr-[0.3rem] rounded-full"
                />
                <span>
                  {auth?.currentUser?.displayName || userEmail
                    ? userEmail?.split("@")[0]
                    : ""}
                </span>
              </div>
            </li>

            <li>
              <p className="border-l-2 border-solid  border-[#A8B0B9] py-4"></p>
            </li>
            <li>
              <div className="flex flex-row gap-x-8 pt-px">
                <Link to="#settings">
                  <Cog6ToothIcon className="mt-[1px] w-6 h-6 border-gray-400" />
                </Link>
                <Link to="/logout" className="text-[#4F5B67]">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
