import React from "react";
import { displayTime, displayShortDate } from "../../utils/dateutils";
import { useLocation } from "react-router-dom";

export default function CurrentLocationCard(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const module = queryParams.get("module") || "eta";
    console.log(props.isExternalDriver)

    return (
        <div className="w-full">
            <p className="text-sm text-[#606479]">
                Current Location:
            </p>
            {props.address ?
                (<div className="pb-4">
                    {props.address}{" "}
                </div>) : (
                    <div className="pb-4">
                        Not available from ELD
                    </div>
                )
            }
            <div>
                {props.eta && !props.isExternalDriver ? (
                    <>
                        {module === "eta" && <div className={`${props.isLate ? "text-[#B40808] font-bold" : ""}`}>
                            {props.eta && <>
                                <p className={`text-sm + ${props.isLate ? "text-[#B40808] font-bold" : "text-[#606479]"}`}>
                                    <span>
                                        ETA:{" "}
                                    </span>
                                </p>
                                {displayTime(props.eta)} {props.delivery_timezone}
                            </>
                            }
                            <span className={`${props.isLate ? "text-[#B40808] font-bold" : ""}`}>
                                {" " + displayShortDate(props.eta)}
                            </span>
                        </div>}
                        <p className="text-sm mt-3">
                            <span className="text-[#606479]">Last Updated: </span></p>{displayTime(props.lastUpdated)} {props.delivery_timezone} {displayShortDate(props.lastUpdated)}
                    </>
                ) : (
                    // <p>ETA: Can’t calculate ETA</p>
                    <></>
                )
                }
            </div>
        </div>
    );
}