import React from "react";
import { useState, useEffect } from "react";
import NoteItem from "../Notes/NoteItem";
import { toast } from "react-toastify";
import CreateNote from "../Notes/CreateNote";

export default function ExceptionNotesSection(props) {
  const [notesId, setNotesId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [showAddNote, setShowAddNote] = useState(false);

  useEffect(() => {
    getTripNotes();
  }, []);

  const getTripNotes = async () => {
    const response = await fetch(process.env.REACT_APP_API_SERVER + `/notes/?trip_id=${props.tripId}`)
    if (response.ok) {
      const data = await response.json();
      setNotes(data);
    } else {
      console.error("Failed to fetch notes", response.json());
      toast.error("Failed to fetch notes");
    }
  }

  const handleAddNoteClick = () => {
    console.log("Adding a note");
    setShowAddNote(!showAddNote);
    setNotesId("");
  };

  return (
    <>
      {showAddNote && (
        <div className="w-full">
          <div className="float-right p-4 rounded mb-4 mt-2 text-sm font-medium">
            <button onClick={() => setShowAddNote(!showAddNote)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {!showAddNote && (
        <div className="w-full">
          <div className="float-right p-4 rounded mb-4 mt-2 text-sm font-medium">
            {showSpinner && (
              <i className="animate-spin fa-solid fa-spinner"></i>
            )}
            <button className="pl-4" onClick={handleAddNoteClick}>
              Add New Note <i className="pl-1 fa-solid fa-square-plus"></i>
            </button>
          </div>
        </div>
      )}
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4 w-full">
          {showAddNote && <CreateNote key="create-note" tripId={props.tripId} noteSaved={reloadNotes} />}
          {notes.map((item, index) => (
            <NoteItem
              noteId={item._id}
              key={index}
              createTime={item.create_time}
              note={item.note}
              type={item.type || "User"}
              createdBy={item.created_by}
              uploads={item.uploads || []}
              reloadNotes={reloadNotes}
            />
          ))}
        </div>
    </>
  );

  async function reloadNotes() {
      setShowAddNote(false);
      setNotes([]);
      await getTripNotes();
      setShowSpinner(false);
  }
}
