import { XMarkIcon } from "@heroicons/react/24/solid";
import Modal from "react-modal";

export const PreviewModal = ({ isOpen, close, children }: {
    isOpen: boolean;
    close: () => void;
    children: any;
}) => {
    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="overlay overlay-map m-auto"
        >
            {children}
            <span
                className="fixed top-0 right-0 p-1 bg-black opacity-50">
                <XMarkIcon
                    className="w-6 h-6 text-white cursor-pointer"
                    onClick={() => close()}
                />
            </span>
        </Modal>)
}