"use client";
import React from "react";
import { useEffect } from "react";
import ExceptionDetailsData from "./exceptionDetailsData";
import CurrentLocationCard from "../../components/CurrentLocation/currentlocation";
import DriverCard from "../../components/Driver/driver";
import CustomerCard from "../../components/CustomerCard/customerCard";
import DeliveryDetailsCard from "../../components/DeliveryDetailsCard/deliveryDetailsCard";
import ExceptonDetailsLayout from "../../components/Layout/exceptonDetailsLayout";
import ShiftStatusCard from "../../components/ShiftStatusCard/shiftStatusCard";
import AvailableHoursCard from "../../components/AvailableHoursCard/availablehourscard";
import ExceptionNotesSection from "../../components/ExceptionNotesSection/exceptionnotessection";
import { fetchData, notesData, setClickIndex, setEtaTab } from "../../redux/reducer/index";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useAuthGuard from "../../hooks/useAuthGuard";
import "./exceptionDetailsCard.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { isExternalDriver, isTemperatureViolation } from "../../utils/triputils";
import { toast } from "react-toastify";
import ExpandableMap from "../../components/map/expandableMap";
import TripDetails from "../../components/TripDetails/tripDetails";
import LoadRequirementsCard from "../../components/TemperatureMonitoring/LoadRequirements";
import ReeferActual from "../../components/TemperatureMonitoring/ReeferActual";
import { isNullOrUndefined } from "../../utils/triputils";

export default function ExceptionDetailsCard() {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, loading } = useAppSelector((state) => state.data);
  const etaTab = useAppSelector((state) => state.data.etaTab);
  let { clickIndex } = useAppSelector((state) => state.data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const module = queryParams.get("module") || "eta";

  let curr_data = [];
  if (clickIndex === undefined || clickIndex === null || !clickIndex) {
    const id = queryParams.get("id");
    if (id) {
      dispatch(setClickIndex(clickIndex));
      curr_data = data?.filter((val) => val.trip_num == id);
    } else {
      navigate("/exceptionList");
    }
  } else {
    curr_data = data?.filter((val) => val.trip_num == clickIndex);
  }

  const { notes } = useAppSelector((state) => state.notes);

  useEffect(() => {
    if (!data?.length && !loading) {
      dispatch(fetchData()).then((actionResult) => {
        if (actionResult.type === "data/fetchData/rejected") {
          toast.error(actionResult.payload);
        }
      });
    }
    dispatch(notesData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setEtaTab(0));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ExceptonDetailsLayout
        leftCardSec={
          <TripDetails
            curr_data={curr_data}
            active_stop_num={curr_data[0]?.active_stop_num} />
        }
        rightCardSec={
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center my-4">
                <div className="mr-6 text-xl text-titleColor font-bold">
                  Trip #{curr_data[0]?.trip_num}
                </div>
              </div>

              <div
                className="bg-indigo-500/100 rounded-sm w-max 
                            px-2 pt-[1px] pb-[1px] text-white text-xs 
                            font-semibold uppercase"
              >
                {
                  <div>
                    NOTES (
                    {
                      notes.filter((val) =>
                        val.trip_id.includes(curr_data[0]?.trip_num)
                      ).length
                    }
                    )
                  </div>
                }
              </div>
            </div>
            {/* TODO: mapping is not required, just read one element from the data and parse it, since it wont be an array */}
            {curr_data?.map((delivery, index) => (
              <div
                className="flex flex-row gap-4 mb-4 w-full"
                key={`del_${index}`}
              >
                <div className="w-3/4 flex flex-col gap-4">
                  <div className="flex w-full">
                    <DriverCard
                      driverName={delivery?.driver?.driver_name}
                      contactPhone={delivery?.driver?.contact_phone}
                      truckNum={delivery?.truck_num}
                      trailerNum={delivery?.trailer_num}
                    />
                  </div>
                  <div className="flex w-full">
                    <CustomerCard
                      customerName={delivery?.customer_name}
                      loadNum={delivery?.load_num}
                    />
                  </div>
                </div>
                <div className="flex p-4 w-1/4 rounded bg-gray-50 text-sm justify-center">
                  <CurrentLocationCard
                    address={delivery?.current_location?.address1}
                    isLate={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.is_late : false}
                    eta={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.eta : null}
                    lastUpdated={delivery?.current_location?.last_updated}
                    delivery_timezone={delivery?.delivery_timezone}
                    isExternalDriver={isExternalDriver(curr_data[0]?.driver)}
                  />
                </div>
              </div>
            ))}


            {isExternalDriver(curr_data[0]?.driver) && module === "eta" && (
              <div className="w-full">
                <div className="p-4 rounded mb-4 bg-blue-500 text-white text-bold text-center">
                  <i className="fa-solid fa-bell me-3"></i>HoS data unavailable
                  for non-company ELD
                </div>
              </div>
            )}

            {!isExternalDriver(curr_data[0]?.driver) && module === "eta" &&
              ExceptionDetailsData.map((delivery, index) => (
                <>
                  <div key={`ex_del_${index}`} className="w-full">
                    <DeliveryDetailsCard
                      delivery={delivery}
                      currentLocation={curr_data[0]?.current_location}
                      destinationLocation={curr_data[0]?.destination_location}
                      local_time_offset={curr_data[0]?.delivery_offset_time}
                      delivery_timezone={curr_data[0]?.delivery_timezone}
                      eta_details={curr_data[0]?.eta_details}
                    />
                  </div>
                  {curr_data[0]?.current_location?.lat && curr_data[0]?.destination_location?.lat ?
                    <ExpandableMap
                      curr_data={curr_data}
                    /> : null}
                </>
              ))}

            {(!isExternalDriver(curr_data[0]?.driver) && module === "eta") &&
              curr_data?.map((delivery, index) => (
                <div className="sm:flex gap-4 mb-4" key={`int_del_${index}`}>
                  <ShiftStatusCard
                    legalTimeStatus={delivery?.driver?.legal_time_status}
                  />
                  <AvailableHoursCard
                    delivery={delivery}
                    currentData={curr_data}
                  />
                </div>
              ))}

            <div className="w-3/4 flex flex-col gap-4">
              {
                module === "tempViolation" &&
                <LoadRequirementsCard
                  allowableRange={curr_data[0]?.temperature_monitoring_details?.allowable_temperature_range}
                  reeferMode={curr_data[0]?.temperature_monitoring_details?.reefer_mode}
                  requiredTemperature={curr_data[0]?.temperature_monitoring_details?.required_temperature}
                />
              }

              {
                module === "tempViolation" && !isNullOrUndefined(curr_data[0]?.temperature_monitoring_details?.temperature_exception_status) &&
                <ReeferActual
                  inViolation={isTemperatureViolation(curr_data[0])}
                  ambientTemp={curr_data[0]?.temperature_monitoring_details?.ambient_temperature}
                  returnTemperature={curr_data[0]?.temperature_monitoring_details?.ambient_temperature}
                  reeferMode={curr_data[0]?.temperature_monitoring_details?.reefer_mode}
                />
              }
            </div>
            <ExceptionNotesSection tripId={curr_data[0]?.trip_num} />
          </div>
        }
      ></ExceptonDetailsLayout>
    </>
  );
}
