export function handleError(error: any, toast?: any, message?: string) {
    console.error("API Error", message, error);
    if (message) {
        // Don't show error message if it's a refresh token error
        if (error?.action !== "refreshToken") {
            toast?.error(message);
        }
    } else {
        const message = error?.message || error?.error || error?.detail || "API Error";
        toast?.error(message);
    }
}