import React from "react";

export default function CustomerCard(props) { 
    return (
        <div className="p-4 rounded sm:mb-0 mb-3 bg-gray-50 w-full">
            <div className="sm:flex text-sm">
                <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                <span className="text-[#606479]">Customer: </span>{" "}
                {props.customerName}
                </div>
                <div className="sm:w-1/2 w-full">
                <span className="text-[#606479]">Order: </span>{" "}
                {props.loadNum}
                </div>
            </div>
            {props.tmsLinkInfo &&
                <div className="text-xs mt-4 text-blue-600"><a target="_blank" href={props.tmsLinkInfo.external_link} rel="noopener noreferrer">View Load in Alvys</a></div>
            }
        </div>
    )
}