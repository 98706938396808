import React from "react";
import Card from "../../components/card/card";

export default function TripDetails(props: {
    curr_data: any;
    active_stop_num: number;
    deriveCardClassName?: (stop: any, props: any) => string;
}) {

    const getDisplayStatus = (stopType, status) => {
        if (stopType == 'Pickup') {
            if (status == 'Completed') {
                return "Picked Up"
            } else {
                return status
            }
        } else if (stopType == 'Delivery') {
            if (status == 'Completed') {
                return "Delivered"
            } else {
                return status
            }
        }
        return status
    }

    return (
        <div className="text-sm">
            {props.curr_data?.map((delivery, index) => (
                <React.Fragment key={`delivery_${index}`}>
                    {delivery?.stops?.map((stops, stopIndex) => (
                        <div key={`stop_${stopIndex}`}>
                            <div
                                className={` ${stopIndex == 0
                                    ? ""
                                    : "border-[#A8B0B9] border-l-4 h-4 relative left-1/2"
                                    }`}
                            ></div>

                            <div
                                className={(stops?.stop_order == props.active_stop_num ? 'border-4 border-indigo-500/100' : '')
                                    + (stops?.stop_order < props.active_stop_num ? 'opacity-40' : '')
                                }
                                style={{
                                    borderRadius: "10px",
                                }}
                            >
                                <Card key={stops.id} className={`${props.deriveCardClassName ? props.deriveCardClassName(stops, props) : ""}`}>
                                    <div className="grid grid-cols-2">
                                        <div
                                            className={`${stops?.deliver_status !== "Pending"
                                                ? "bg-[#1C244A] rounded-sm w-max px-1 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase"
                                                : "bg-[#d84a4c] rounded-sm w-max px-1 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase"
                                                }`}
                                        >

                                            {`${stops?.stop_type} ${stops?.stop_order
                                                }`}
                                        </div>
                                        {stops?.stop_order == props.active_stop_num &&
                                            <div><i className="mt-0.5 fa-solid fa-truck"></i></div>
                                        }
                                    </div>
                                    <div
                                        // className={`mt-2 text-sm font-medium ${  stops.deliver_status !== "Pending"}? text-[#141414] : text-[red]`}

                                        className={`mt-2 text-sm ${stops?.deliver_status !== "Pending"
                                            ? "text-[#141414]"
                                            : "text-[red]"
                                            }`}
                                    >
                                        {/* <p className="mb-2">{delivery?.stops?.length}</p> */}
                                        <p className="mb-2">
                                            <span className="text-[#606479]">Customer:{" "}</span>
                                            {stops?.customer_name ? stops?.customer_name : "-"}{" "}
                                        </p>
                                        <p className="mb-2"><span className="text-[#606479]">Location: </span>{`${stops?.location.city}, ${stops?.location.state}`}</p>
                                        <p className="mb-2"><span className="text-[#606479]">Date: </span>{`${new Date(
                                            stops?.deliver_date
                                        ).toLocaleDateString("en-US", {
                                            timeZone: "UTC",
                                        })}`}</p>
                                        <p className="mb-2">
                                            <span className="text-[#606479]">
                                                Appt Type:
                                            </span>{" "}
                                            {`${stops?.appointment_type}`}
                                        </p>
                                        <p className="mb-2">
                                            <span
                                                className={`text-[#606479] ${stops.deliver_status !== "Pending"
                                                    ? ""
                                                    : "text-[red]"
                                                    }`}
                                            >
                                                Appt Time:
                                            </span>{" "}
                                            {new Date(stops?.deliver_date).toLocaleTimeString(
                                                "en-US",
                                                {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                    timeZone: "UTC",
                                                }
                                            )}
                                        </p>
                                        <p className="mb-2"><span className="text-[#606479]">Status: </span>{`${getDisplayStatus(stops?.stop_type, stops?.deliver_status)}`}</p>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </div>
    )
}