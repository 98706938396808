import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData } from "../../redux/reducer/index";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";

const TripCard = () => {
  const { data, loading } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        toast.error(actionResult.payload);
      }
    });
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      title="Trip ETA"
      tripData={{
        "Company Driver Trips": {
          Late: getFilteredData(data, "internal", { tripTiming: "lateTrips" }).length,
          "On Time": getFilteredData(data, "internal", { tripTiming: "onTimeTrips" }).length,
        },
        "External Driver Trips": {
          Late: getFilteredData(data, "external", { tripTiming: "lateTrips" }).length,
          "On Time": getFilteredData(data, "external", { tripTiming: "onTimeTrips" }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          Late: `/exceptionList?tripType=internal&tripTiming=lateTrips&module=eta`,
          "On Time": `/exceptionList?tripType=internal&tripTiming=onTimeTrips&module=eta`,
        },
        "External Driver Trips": {
          Late: `/exceptionList?tripType=external&tripTiming=lateTrips&module=eta`,
          "On Time": `/exceptionList?tripType=external&tripTiming=onTimeTrips&module=eta`,
        },
      }}
    />
  );
};

export default TripCard;
