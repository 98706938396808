import { beautifyTime } from "../../utils/dateutils";
import { formatCurrency } from "../../utils/currency";

type Duration = {
    value: number,
    unit: string;
}

type DetentionSummaryCardProps = {
    detentionHours: Duration;
    detentionRate: number;
    detentionAmount: number;
    lossHours: Duration;
    lossRate: number;
    lossAmount: number;
}
export default function DetentionSummaryCard(props: DetentionSummaryCardProps) {
    const { detentionHours, detentionRate, detentionAmount, lossHours, lossRate, lossAmount } = props;

    return (
        <div className="flex flex-col w-full bg-gray-50 p-4 rounded gap-6">
            <div className="inline-block">
                <span className="py-0.5 px-2 text-gray-600 bg-gray-200 text-sm font-semibold">DETENTION SUMMARY</span>
            </div>
            <div className="flex flex-row gap-12">
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Detention Hours:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{beautifyTime(detentionHours?.value, "0")}</b>
                    </div>
                </div>
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Detention Rate:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{formatCurrency(detentionRate, "$0")}/Hour</b>
                    </div>
                </div>
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Detention Amount:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{formatCurrency(detentionAmount, "$0")}</b>
                    </div>
                </div>
            </div>
            <div>
                <hr className="border-[#606479]" />
            </div>
            <div className="inline-block">
                <span className="py-0.5 px-2 text-gray-600 bg-gray-200 text-sm font-semibold">LOSS SUMMARY</span>
            </div>
            <div className="flex flex-row gap-12">
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Loss Hours:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{beautifyTime(lossHours?.value, "0")}</b>
                    </div>
                </div>
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Loss Rate:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{formatCurrency(lossRate, "$0")}/Hour</b>
                    </div>
                </div>
                <div className="flex flex-col gap-4 min-w-32">
                    <div className="text-sm text-[#606479]">
                        Loss Amount:
                    </div>
                    <div className="text-lg text-[#1C244A]">
                        <b>{formatCurrency(lossAmount, "$0")}</b>
                    </div>
                </div>
            </div>
        </div>
    );
}