import { PreviewModal } from "./PreviewModal";

function ImagePreview({ blob, isOpen, close, filename }: {
    blob: Blob;
    isOpen: boolean;
    close: () => void;
    filename: string;
}
) {

    return (
        <PreviewModal isOpen={isOpen} close={close}>
            <div className="flex flex-col items-center bg-slate-500 h-full w-full">
                <img className="m-auto" src={URL.createObjectURL(blob)} alt={filename} />
            </div>
        </PreviewModal>
    );
}

export default ImagePreview;