export type TripTiming = "lateTrips" | "onTimeTrips" | "";
export type TripType = "internal" | "external";

export const getFilteredData = (data: any[], tripType: TripType, filter: {
  tripTiming?: TripTiming
  temperatureViolation?: boolean
}) => {
  let filteredData = data;
  if (tripType === "internal") {
    filteredData = data?.filter((d) => Boolean(d?.driver?.samsara_id));
  } else if (tripType === "external") {
    filteredData = data?.filter((d) => !Boolean(d?.driver?.samsara_id));
  }
  if (filter?.tripTiming) {
    if (filter.tripTiming === "lateTrips") {
      filteredData = filteredData?.filter((d) => Boolean(d?.eta_details?.is_late));
    } else if (filter.tripTiming === "onTimeTrips") {
      filteredData = filteredData?.filter((d) => !Boolean(d?.eta_details?.is_late));
    }
  }
  if (filter?.temperatureViolation !== undefined) {
    filteredData = filteredData?.filter((d) => isTemperatureViolation(d) === filter.temperatureViolation);
  }
  return filteredData;
};

export const isTemperatureViolation = (trip: any) => {
  if (trip?.temperature_monitoring_details) {
    if (typeof trip?.temperature_monitoring_details === "object") {
      if (Object.keys(trip?.temperature_monitoring_details).length === 0) {
        return false;
      } else {
        if (isNullOrUndefined(trip?.temperature_monitoring_details?.temperature_exception_status)) {
          return false;
        } else {
          return trip?.temperature_monitoring_details?.temperature_exception_status;
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
}

export const isExternalDriver = (driver: any) => {
  return !Boolean(driver?.samsara_id)
}