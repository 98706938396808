import React from "react";
import { useState } from "react";
import { displayTime, displayShortDate, getCurrentLocalTimeWithOffset } from "../../utils/dateutils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setEtaTab } from "../../redux/reducer/index";

export default function DeliveryDetailsCard(props) {
    const dispatch = useAppDispatch();
    const etaTab = useAppSelector((state) => state.data.etaTab);
    const [etaCalcs, setEtaCalcs] = useState(props.eta_details && props.eta_details.eta_calculations ? props.eta_details.eta_calculations[etaTab] : {});

    const changeCalc = (tab) => {
      if (props.eta_details && props.eta_details.eta_calculations) {
        dispatch(setEtaTab(tab));
        setEtaCalcs(props.eta_details.eta_calculations[tab])
      }
    }

    return (
      <>
        {etaCalcs.eta &&
          <div className="p-4 rounded mb-4 bg-gray-50 ">
            {props.eta_details && props.eta_details.eta_calculations &&
              <div className="text-sm mb-4 flex flex-row justify-center items-center">
                <button onClick={() => changeCalc(0)} className={'font-semibold py-2 px-4 border rounded ' + (etaTab == 0 ? 'bg-blue-500 text-white border-transparent' : 'bg-transparent text-indigo-500/100 border-indigo-500/100')}>Use Remaining Hours</button>
                <button onClick={() => changeCalc(1)} className={'font-semibold py-2 px-4 border rounded ' + (etaTab == 1 ? 'bg-blue-500 text-white border-transparent' : 'bg-transparent text-indigo-500/100 border-indigo-500/100')}>10-hour Break</button>
              </div>
            }
            {props.eta_details ? (props.delivery.notes.map((_, index) => (
              <React.Fragment key={`notes_${index}`}>
                <div className="text-[#606479] mb-3">Trip Timeline Projections</div>
                <p className="mb-6 ml-4 text-sm">
                  <span className="mr-1 text-sm">
                    {!etaCalcs.eta ? (
                      <div className="font-normal">Current location is unavailable from ELD. So can’t provide delivery details.</div>
                    ) : etaCalcs.is_late ? (
                      <div className="font-semibold">Arrival is expected late and after appointment time</div>
                    ) : (
                      <div className="font-normal">Arrival is expected early or on-time</div>
                    )}
                  </span>
                </p>
                {etaCalcs.drive_timedetails && etaCalcs.break_timedetails && etaCalcs.eta_timedetails &&
                  <>
                    <div className="ml-4 text-[#606479] mb-2">Trip Data</div>
                    <div className="ml-8 text-sm text-[#1F2B37]">Miles to Destination: {props.eta_details.drive_miles_remaining} <span className="text-xs">miles</span></div>
                    {etaCalcs.calc_details && !etaCalcs.is_late_and_delivery_due ? (
                      <div className="ml-8 text-sm text-[#1F2B37]">Total available time from current time to appointment time: {etaCalcs.time_to_delivery.hours} <span className="text-xs">hrs</span> {etaCalcs.time_to_delivery.minutes} <span className="text-xs">mins</span></div>
                    ) :
                      (
                        <div className="ml-8 text-sm text-[#1F2B37]">Total available time from current time to appointment time: 0 <span className="text-xs">hrs</span> 0 <span className="text-xs">mins</span></div>
                      )}
                    <div className="ml-8 text-sm text-[#1F2B37]">Total driving time and mandatory break time needed to arrive at Stop: {etaCalcs.eta_timedetails.hours} <span className="text-xs">hrs</span> {etaCalcs.eta_timedetails.minutes} <span className="text-xs">mins</span></div>
                    <div className="ml-12 text-sm text-[#1F2B37]">Total driving time needed: {etaCalcs.drive_timedetails.hours} <span className="text-xs">hrs</span> {etaCalcs.drive_timedetails.minutes} <span className="text-xs">mins</span></div>
                    <div className="ml-12 mb-2 text-sm text-[#1F2B37]">Total mandatory break time required: {etaCalcs.break_timedetails.hours} <span className="text-xs">hrs</span> {etaCalcs.break_timedetails.minutes} <span className="text-xs">min</span></div>

                    {etaCalcs.is_late ? (
                      <div className="ml-8 text-sm font-semibold">Reason for delay: Total available time is less than driving + break time by {etaCalcs.eta_deliverytime_delta.hours} <span className="text-xs">hrs</span> {etaCalcs.eta_deliverytime_delta.minutes} <span className="text-xs">mins</span></div>
                    ) : (
                      <div className="ml-8 text-sm font-normal">Total available time is more than driving + break time by {etaCalcs.eta_deliverytime_delta.hours} <span className="text-xs">hrs</span> {etaCalcs.eta_deliverytime_delta.minutes} <span className="text-xs">mins</span></div>
                    )}
                  </>
                }
                {props.local_time_offset && props.delivery_timezone && props.currentLocation.lat ?
                  <>
                    <div className="ml-4 text-[#606479] mb-2 mt-6">Projected Trip Timeline</div>
                    <div className="ml-8 text-sm text-[#1F2B37]">Current time at destination: {displayTime(getCurrentLocalTimeWithOffset(props.local_time_offset))} {props.delivery_timezone} on {displayShortDate(getCurrentLocalTimeWithOffset(props.local_time_offset))}</div>
                  </> : null
                }
                {etaCalcs.calc_details && etaCalcs.calc_details.length > 0 &&
                  <div className="ml-8 mr-8 mt-4 mb-4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-600">
                      <thead className="text-xs text-gray-600 uppercase bg-gray-50">
                        <tr>
                          <th className="text-[#606479]">Time bucket</th>
                          <th className="text-[#606479]">Duration</th>
                          <th className="text-[#606479]">Start Time</th>
                          <th className="text-[#606479]">End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {etaCalcs.calc_details.map((step, i) => (
                          step.duration &&
                          <tr className="bg-white border-b" key={i}>
                            <td className="text-sm">{step.description}</td>
                            <td className="text-sm">{step.duration.hours} <span className="text-xs">hrs</span> {step.duration.minutes} <span className="text-xs">mins</span></td>
                            <td className="text-sm">{displayShortDate(step.start_time)} {displayTime(step.start_time)}</td>
                            <td className="text-sm">{displayShortDate(step.end_time)} {displayTime(step.end_time)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
                {props.eta ?
                  ((etaCalcs.eta_deliverytime_delta && etaCalcs.is_late) ? (
                    <div className="ml-8 text-sm font-semibold">Projected arrival: {displayTime(props.eta)} {props.delivery_timezone} on {displayShortDate(etaCalcs.eta)} ({etaCalcs.eta_deliverytime_delta.hours} <span className="text-xs">hrs</span> {etaCalcs.eta_deliverytime_delta.minutes} <span className="text-xs">mins</span> after appointment time)</div>
                  ) : (
                    etaCalcs.eta_deliverytime_delta &&
                    <div className="ml-8 text-sm font-normal">Projected arrival: {displayTime(props.eta)} {props.delivery_timezone} on {displayShortDate(etaCalcs.eta)} ({etaCalcs.eta_deliverytime_delta.hours} <span className="text-xs">hrs</span> {etaCalcs.eta_deliverytime_delta.minutes} <span className="text-xs">mins</span> before appointment time)</div>
                  )) : (
                    null
                  )
                }

                {etaCalcs.eta_step_details && etaCalcs.is_late &&
                  <>
                    <div className="ml-4 text-[#606479] mb-2 mt-6">HoS Data</div>
                    {!etaCalcs.is_late_and_delivery_due &&
                      <div className="ml-8 text-sm text-[#1F2B37]">Available HoS hours during trip: {etaCalcs.time_driven_at_eta.hours} <span className="text-xs">hrs</span> {etaCalcs.time_driven_at_eta.minutes} <span className="text-xs">mins</span></div>
                    }
                    {etaCalcs.is_late_and_delivery_due &&
                      <div className="ml-8 text-sm text-[#1F2B37]">Available HoS hours during trip: 0 <span className="text-xs">hrs</span> 0 <span className="text-xs">mins</span></div>
                    }
                    <div className="ml-8 text-sm text-[#1F2B37]">Driving hours needed for trip: {etaCalcs.drive_timedetails.hours} <span className="text-xs">hrs</span> {etaCalcs.drive_timedetails.minutes} <span className="text-xs">mins</span></div>
                    {!etaCalcs.is_late_and_delivery_due &&
                      <div className="ml-8 mt-2 text-sm font-semibold">Available HoS hours are less than driving hours by {props.time_remaining_at_eta.hours} <span className="text-xs">hrs</span> {props.time_remaining_at_eta.minutes} <span className="text-xs">mins</span></div>
                    }
                    {etaCalcs.is_late_and_delivery_due &&
                      <div className="ml-8 mt-2 text-sm font-semibold">The delivery is already late.</div>
                    }
                  </>
                }
              </React.Fragment>
            ))) : (
              <>
                <div className="text-[#606479] mb-3">Trip Details not available since location not available from ELD</div>
              </>
            )}
          </div>
        }
      </>
    )
}