"use client";
import { signOut } from "../../firebase/config";
import { useAppDispatch } from "../../redux/hooks";
import { resetStore } from "../../redux/reducer/index";
import { FormEvent } from "react";
import useAuthGuard from "../../hooks/useAuthGuard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { mapAuthCodeToMessage } from "../../firebase/helper";
const Logo = require("../../../assets/images/navbar/logo.png");

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useAuthGuard();

  async function logoutUser(event: FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      toast.success("Logged out successfully");
      dispatch(resetStore());
      await signOut();
      navigate("/login");
    } catch (e) {
      console.log("Error");
      console.error(e);
      toast.error(
        e?.code ? mapAuthCodeToMessage(e.code) || e.message : e.message
      );
    }
  }
  return (
    <div className="flex min-h-full flex-col px-6 py-12 lg:px-6">
      <div className="flex justify-center w-full mt-20 mb-32">
        <img src={Logo} width={200} height={200} alt="logo" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl p-10">
        <h2 className="mb-16 text-center text-2xl leading-9 tracking-tight text-gray-900">
          Log out from your account?
        </h2>
        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          <form className="space-y-6" onSubmit={logoutUser}>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Log Out
              </button>
            </div>
            <div>
              <button
                type="button"
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-500 shadow-sm hover:bg-indigo-500 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border-indigo-500 border-2 hover:text-white"
                onClick={() => {
                  navigate(-1);
                  console.log("Cancel");
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
